import Grid from '@mui/material/Grid';
import { useState, useEffect, ChangeEvent } from 'react';
import { Role } from '../../../services/interfaces';

import ModalHeading from './ModalHeading';
import RoleItem from './RoleItem';

const ROLES = [
  {
    name: Role.ADMIN,
    label: 'Admin',
    description:
      'Edit accounts, launch Live Fire scenarios, ' +
      'provide Live Fire feedback to analysts, and monitor team performance',
  },
  {
    name: Role.ANALYST,
    label: 'Analyst',
    description: 'Be assigned Live Fire scenarios and track personnal performance',
  },
  {
    name: Role.INSTRUCTOR,
    label: 'Instructor',
    description: 'Manage analysts, curriculum and Live Fire training',
  },
  {
    name: Role.BUSINESS,
    label: 'Business Analyst',
    description: 'View business risk, scoring and nutrition.',
  },
  {
    name: Role.FRAMEWORK_ANALYST,
    label: 'Framework Analyst',
    description: 'View framework and evaluation results',
  },
];

export default function Roles({ error = '', roles = [], onChange, showErrors = false }: RolesProps) {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (!isDirty && showErrors) {
      setIsDirty(true);
    }
  }, [showErrors, isDirty]);

  const handleUpdate = (e: ChangeEvent<unknown>, checked: boolean) => {
    const {
      target: { value },
    } = e as ChangeEvent<HTMLInputElement>;

    setIsDirty(true);
    let updated = [];

    if (checked) {
      updated = [...roles, value];
    } else {
      updated = roles.filter(r => r !== value);
    }

    onChange({ target: { value: updated } });
  };

  return (
    <>
      <ModalHeading title="User Role(s)" error={isDirty ? error : ''} sub />
      <Grid container item direction="column">
        {ROLES.map(role => (
          <RoleItem
            {...role}
            key={`account-role-checkbox-${role.name}`}
            checked={roles.includes(role.name)}
            onUpdate={handleUpdate}
          />
        ))}
      </Grid>
    </>
  );
}
interface RolesProps {
  error?: string;
  roles?: string[];
  onChange: (e: { target: { value: string[] } }) => void;
  showErrors?: boolean;
}
