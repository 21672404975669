import { differenceInHours, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { randomString } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import ButtonLink from '../../../reusables/ButtonLink';
import { settingsService } from '../../../services';
import { Setting } from '../../../services/interfaces';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';

const SETTING_NAME = 'registrationToken';
const SETTING_FIELDS = '{ name value updatedAt }';

const useStyles = makeStyles(theme => ({
  selfSignUp: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15,
  },
  expire: {
    '&>*': {
      color: 'red',
    },
  },
  createLink: {
    display: 'flex',
    flexDirection: 'column',
  },
  tip: {
    fontSize: '12px',
    color: theme.palette.grey2.main,
  },
  copy: {
    padding: '2px 6px 6px',
  },
}));

export default function SelfSignupLink() {
  const classes = useStyles();
  const [registrationToken, setRegistrationToken] = useState<Setting>();
  const [isCopied, setIsCopied] = useState(false);
  const url = window.location.href.replace(/\/settings\/accounts/, '/signup/');

  useAbort(
    () => settingsService.find(SETTING_NAME, SETTING_FIELDS),
    token => {
      const updatedAt = parseISO(token.updatedAt.toISOString());
      const hoursSinceUpdate = differenceInHours(new Date(), updatedAt);
      const expiresAt = 72;

      // must exist and can't be older than 72 hours
      if (!token.value || hoursSinceUpdate > expiresAt) {
        return;
      }

      setRegistrationToken(token);
    },
    [],
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const timeoutMs = 5000;

    if (isCopied) {
      timeoutId = setTimeout(() => setIsCopied(false), timeoutMs);
    }

    return () => clearTimeout(timeoutId);
  }, [isCopied]);

  const saveRegistrationToken = async (value = '') => {
    appStore.beginLoading();
    try {
      if (value.length === 0) {
        await navigator.clipboard.writeText('');
      }
      const token = await settingsService.save({ name: SETTING_NAME, value }, SETTING_FIELDS);
      setRegistrationToken(token.value ? token : undefined);
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  const destination = `${url}${registrationToken?.value}`;

  const handleCopyRegistrationToken = async () => {
    try {
      await navigator.clipboard.writeText(destination);
      setIsCopied(true);
    } catch (err) {
      appStore.error(err);
    }
  };

  return (
    <div className={classes.selfSignUp}>
      {!registrationToken?.value && (
        <div className={classes.createLink}>
          <ButtonLink data-testid="self-signup-create" onClick={() => saveRegistrationToken(randomString(5))}>
            Create Self-Sign-Up Link
          </ButtonLink>
          <span className={classes.tip}>Automatically expires in 72 hours</span>
        </div>
      )}

      {registrationToken?.value && (
        <>
          <ButtonLink
            data-testid="self-signup-link"
            onClick={() => window.open(destination, 'signup', 'noopener,noreferrer')}
          >
            {destination}
          </ButtonLink>
          <IconButton
            size="small"
            className={classes.copy}
            disabled={isCopied}
            data-testid="self-signup-copy"
            onClick={handleCopyRegistrationToken}
          >
            {isCopied ? (
              <CheckIcon color="success" data-testid="check-icon" />
            ) : (
              <ContentCopyIcon color="primary" data-testid="content-copy-icon" />
            )}
          </IconButton>
          <ButtonLink
            data-testid="self-signup-expire"
            className={classes.expire}
            onClick={() => saveRegistrationToken()}
          >
            (expire now)
          </ButtonLink>
        </>
      )}
    </div>
  );
}
