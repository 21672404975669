import { makeStyles } from '@mui/styles';
import { Paper, Tabs as MTabs, Tab, TabsOwnProps } from '@mui/material';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '100%',
    borderRadius: 6,
    '& > div': {
      borderRadius: 6,
    },
  },
  fit: {
    width: 'fit-content',
    height: 'fit-content',
  },
}));

interface TabsProps {
  className?: string;
  selected: number;
  onSelect: (value: number) => void;
  labels: string[];
  variant?: TabsOwnProps['variant'];
  scrollButtons?: 'auto' | boolean;
}

export default function Tabs({
  className = '',
  variant = 'standard',
  scrollButtons = false,
  selected,
  labels,
  onSelect,
}: TabsProps) {
  const classes = useStyles();
  const fitClass = variant !== 'fullWidth' ? classes.fit : '';

  return (
    <Paper square className={`${classes.paper} ${className} ${fitClass}`}>
      <MTabs
        indicatorColor="primary"
        textColor="primary"
        value={selected}
        onChange={(_, v) => onSelect(v)}
        variant={variant}
        scrollButtons={scrollButtons}
      >
        {labels.map(label => (
          <Tab key={label} label={label} />
        ))}
      </MTabs>
    </Paper>
  );
}
