import LessonText from './LessonText';
import LessonLabels from './LessonLabels';
import LessonList from './LessonList';
import LessonReference from './LessonReference';
import renderComponent, { registerComponent } from './renderComponent';

registerComponent('LessonText', LessonText);
registerComponent('LessonLabels', LessonLabels);
registerComponent('LessonList', LessonList);
registerComponent('LessonReference', LessonReference);

export { renderComponent, LessonText, LessonLabels, LessonList };
