import { FormEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import { Button, Grid, InputAdornment, MenuItem } from '@mui/material';
import { useAbort } from '../../../../effects';
import Modal from '../../../reusables/Modal';
import PasswordComplexity from '../../../reusables/PasswordComplexity';
import PasswordText from '../../../reusables/PasswordText';
import TextField from '../../../reusables/TextField';
import { UserGroup, UserInput } from '../../../services/interfaces';
import { EditableUser, EditingUser } from './interfaces';
import ModalHeading from './ModalHeading';
import Roles from './Roles';

// validates password strength
const passwordTest = (p: string) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(p);

// validates the confirm password field
const confirmPasswordTest = (p?: string, c?: string) => {
  if (!p) {
    return '';
  }

  if (!c) {
    return 'Confirm Password is a required field';
  }

  if (c !== p) {
    return 'Passwords do not match';
  }

  return '';
};

// validates an email address
const emailTest = (e?: string) => {
  if (!e || !e.trim()) {
    return 'Email is a required field';
  }

  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e)) {
    return 'Invalid email address';
  }

  return '';
};

// validation rules
const validationRules: ValidationRule[] = [
  ({ name }) => ({ name: !name || !name.trim() ? 'Name is a required field' : '' }),
  ({ id, password }) => ({ password: !id && !password ? 'Password is a required field' : '' }),
  ({ password, confirmPassword }) => ({ confirmPassword: confirmPasswordTest(password, confirmPassword) }),
  ({ password }) => ({ badPassword: !!(password && !passwordTest(password)) }),
  ({ email }) => ({ email: emailTest(email) }),
  ({ roles }) => ({ roles: !(roles && roles.length) ? 'Role is required' : '' }),
];

export default function EditAccountModal({
  groups,
  selectedUser,
  onSave,
  onClose,
  verifyUniqueEmail,
}: EditAccountModalProps) {
  // get the classes
  // indicates if any changes have been made to enable/disable the submit
  const [isDirty, setIsDirty] = useState(false);
  // indicates if submit was clicked in order to show all form validation
  const [submitClicked, setSubmitClicked] = useState(false);

  // indicates the account that is current being edited
  const [account, setAccount] = useState<EditingUser>({
    name: '',
    email: '',
    aliases: '',
    status: 'Active' as 'Active' | 'Disabled',
    group: 'Other Analysts',
    roles: [],
    password: '',
    confirmPassword: '',
  });

  useAbort(
    () => ({
      ...account,
      ...selectedUser,
      roles: selectedUser ? selectedUser.roles : [],
    }),
    acct => setAccount(acct),
    [selectedUser],
  );

  // error tracking
  const [errors, setErrors] = useState<EditAccountModalErrors>({});

  // handles account validation
  const validateForm = (acct: EditingUser) => {
    // something changed, allow the sumbmit button
    setIsDirty(true);

    // run the validation rules
    const errs = validationRules.reduce((a, b) => {
      return { ...a, ...b(acct) };
    }, {} as EditAccountModalErrors);

    // validate unique email for client
    if (email && !errs.email && !verifyUniqueEmail(acct.email, acct.id)) {
      errs.email = 'Email address is already in use';
    }

    setErrors(errs);

    return errs;
  };

  /**
   * Handle change to field value
   * @param field name of account property to be updated
   * @returns a function to handle state update and form validation
   */
  const handleUpdate = (field: keyof EditingUser) => {
    return ({ target: { value } }: { target: { value: string | string[] | undefined } }) => {
      const updated: EditingUser = { ...account, [field]: value };
      validateForm(updated);
      setAccount(updated);
    };
  };

  /**
   * Handles the form submit event
   */
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    // display all validation now
    setSubmitClicked(true);
    validateForm(account);

    // cannot save if there are errors
    if (hasErrors()) {
      return;
    }

    const { id, name, password, group, email, aliases = '', status, roles = [] } = account;

    await onSave({
      id,
      name,
      password,
      group,
      email,
      aliases: aliases.split(',').map(a => a.trim()),
      status,
      roles,
    });
    setIsDirty(false);
    setSubmitClicked(false);
  };

  const hasErrors = () => !!Object.values(errors).filter(v => v).length;

  const { id, name, password, confirmPassword, group, email, aliases, status, roles } = account;

  const formTitle = `${id ? 'Edit' : 'Create'} Account`;

  return (
    <Modal open={!!selectedUser} onClose={() => onClose()} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <ModalHeading title={formTitle} status={status} onChange={handleUpdate('status')} />
        {/* name */}
        <Grid item md={6} xs={6}>
          <TextField
            onChange={handleUpdate('name')}
            startAdornment={
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            }
            value={name}
            label="Name"
            error={errors.name}
            inputProps={{ 'data-testid': 'Name' }}
          />
        </Grid>

        {/* email */}
        <Grid item md={6} xs={6}>
          <TextField
            disabled={!!id}
            onChange={handleUpdate('email')}
            value={email}
            error={errors.email}
            startAdornment={
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            }
            label="Email"
            inputProps={{ 'data-testid': 'Email' }}
          />
        </Grid>
        {/* password */}
        <Grid item md={6} xs={6}>
          <PasswordText
            label="Password"
            password={password}
            onChange={handleUpdate('password')}
            error={errors.password}
          />
        </Grid>

        {/* confirmPassword */}
        <Grid item md={6} xs={6}>
          <PasswordText
            label="Confirm Password"
            password={confirmPassword}
            onChange={handleUpdate('confirmPassword')}
            error={errors.confirmPassword}
          />
        </Grid>

        {/* password complexity display */}
        <Grid item xs={12} hidden={!errors.badPassword}>
          <Grid container item>
            <PasswordComplexity password={password} />
          </Grid>
        </Grid>

        <Grid item md={6} xs={6}>
          {/* group */}
          <TextField
            onChange={handleUpdate('group')}
            type="select"
            label="Group"
            inputProps={{ 'data-testid': 'Group' }}
            value={group}
          >
            {[...groups, { id: 0, name: 'Other Analysts' }].map(group => (
              <MenuItem key={group.id} value={group.name}>
                {group.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item md={6} xs={6}>
          {/* aliases */}
          <TextField
            onChange={handleUpdate('aliases')}
            value={aliases}
            label="Aliases"
            inputProps={{ 'data-testid': 'Aliases' }}
            placeholder='Separate aliases with a comma ","'
          />
        </Grid>

        {/* user roles */}
        <Roles roles={roles} onChange={handleUpdate('roles')} error={errors.roles} showErrors={submitClicked} />

        <Grid container item justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isDirty || hasErrors()}
            data-testid="save-new-item"
          >
            Save &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={['fal', 'arrow-right']} />
          </Button>
          <Button variant="outlined" data-testid="cancel-new-item" style={{ marginLeft: 24 }} onClick={() => onClose()}>
            Cancel &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={['fal', 'arrow-right']} />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

interface EditAccountModalErrors {
  name?: string;
  email?: string;
  roles?: string;
  group?: string;
  password?: string;
  confirmPassword?: string;
  badPassword?: boolean;
}

type ValidationRule = (editingUser: EditingUser) => EditAccountModalErrors;

type EditAccountModalProps = {
  groups: UserGroup[];
  selectedUser?: EditableUser;
  onClose: () => void;
  onSave: (input: UserInput) => Promise<void>;
  verifyUniqueEmail: (email?: string, uid?: string) => boolean;
};
