import { round } from '@sightgain/core/calculations';
import { FilterResult } from '../../../../reusables/Filter/interfaces';
import {
  FrameworkItem,
  Job,
  JobsFilter,
  LiveFireTraining,
  LiveFireTrainingStatus,
} from '../../../../services/interfaces';

export const STATUSES = [
  'all statuses',
  'waiting',
  'running',
  'stoppage time',
  'ended',
  'completed',
  'errored',
  'discarded',
  'sleeping',
  'passed',
  'cancelled',
  'queued',
  'released',
];

type LFTFields = 'id' | 'isReleased' | 'isExam' | 'status' | 'analyst' | 'createdAt' | 'instructorScore' | 'score';
export type LFT = Pick<LiveFireTraining, LFTFields>;

type RRJobFields = 'id' | 'status' | 'createdAt' | 'evaluationId' | 'name' | 'isExcluded' | 'frameworkControls';
export type RRJob = Pick<Job, RRJobFields> & {
  trainings: LFT[];
};

export interface DataRow {
  id: string;
  evaluationId: string;
  type: 'lft' | 'job';
  jobId: string;
  name: string;
  status: LiveFireTrainingStatus;
  vendorId: string;
  createdAt: Date;
  analyst?: string;
  score?: number;
  isReleased?: boolean;
  isExam?: boolean;
  isExcluded?: boolean;
  instructorScore?: number;
  frameworkControls?: FrameworkItem[];
}

export function toRow(row: RRJob): DataRow | DataRow[] {
  const jobRow: DataRow = {
    id: row.id,
    jobId: row.id,
    type: 'job',
    evaluationId: row.evaluationId,
    name: row.name,
    status: row.status as LiveFireTrainingStatus,
    vendorId: row.evaluationId,
    createdAt: row.createdAt,
    isExcluded: row.isExcluded,
    frameworkControls: row.frameworkControls,
  };

  if (!row.trainings.length) {
    return jobRow;
  }

  return row.trainings.map(training => ({
    ...jobRow,
    id: training.id,
    type: 'lft',
    status: training.status,
    analyst: training.analyst.email,
    score: round(training.score * 100, 0),
    instructorScore: training.instructorScore,
    isReleased: training.isReleased,
    isExam: training.isExam,
  }));
}

export function toJobsFilter(filters: FilterResult[], type: string) {
  const filter = filters.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.name]: filter,
    };
  }, {} as Record<string, FilterResult>);

  return {
    type: type || undefined,
    ...(filter['status'] && { status: filter['status'].value }),
    ...(filter['name'] && { name: filter['name'].value, nameMatch: filter['name'].operation }),
    ...(filter['evaluationId'] && {
      evaluationId: filter['evaluationId'].value,
      evaluationIdMatch: filter['evaluationId'].operation,
    }),
    ...(filter['isExcluded'] !== undefined && { isExcluded: filter['isExcluded'].value }),
    ...(filter['analystEmail'] && {
      analystEmail: filter['analystEmail'].value,
      analystEmailMatch: filter['analystEmail'].operation,
    }),
    ...(filter['startDate'] && { startDate: filter['startDate'].value, startDateMatch: filter['startDate'].operation }),
  } as JobsFilter;
}
