import { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { ScrollableList } from '../../../reusables/scl';
import useActivityWatcher from '../../../reusables/useActivityWatcher';
import { ActivityLFT } from '../../../reusables/useActivityWatcher/interfaces';
import { TrainingGroupPreview } from '../../../services/interfaces';
import ActivityCard from './ActivityCard';
import CompletedActivities from './CompletedActivities';
import SelectedActivity from './SelectedActivity';
import SelectedTraining from './SelectedTraining';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
  title: {
    marginBottom: 15,
  },
  toggle: {
    marginBottom: 20,
  },
  layout: {
    display: 'flex',
    height: 'calc(100% - 100px)',
  },
  columnLeft: {
    flex: 1,
    paddingRight: 25,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  columnRight: {
    flex: 1,
    paddingLeft: 25,
    height: '100%',
  },
  completedCoursesLabel: {
    color: '#ffffff',
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  completedCourses: {
    marginTop: 36,
    flex: 1,
  },

  incompleteItemsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  incompleteItemsHeaderLabel: {
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  incompleteItemsHeaderOptions: {
    '& > *': {
      marginLeft: 16,
    },
  },
  incompleteItemsList: {
    height: 'calc(100% - 56px)',
  },
}));

const groupChildType = (tab: ActivityTab) => {
  if (tab === 'curriculums') {
    return 'Courses';
  }
  if (tab === 'courses') {
    return 'Classes';
  }
  if (tab === 'exams') {
    return 'Exams';
  }

  return '';
};

export default function Activity() {
  const classes = useStyles();
  const { tab = 'curriculums' } = useParams<{ tab: ActivityTab }>();
  const history = useHistory();
  const [sortBy, setSortBy] = useState('progress');

  const activity = useActivityWatcher(tab, appStore.userId);

  const [selectedActivityId, setSelectedActivityId] = useState<string>();
  const selectedTraining =
    (tab === 'evaluations' || tab === 'exams') && selectedActivityId
      ? (activity.completed as ActivityLFT[]).find(l => l.id === selectedActivityId)
      : undefined;

  const handleTabChange = (value: ActivityTab) => {
    setSelectedActivityId(undefined);
    history.push(`/curriculum/activity/${value}`.replace(/\/curriculums$/, ''));
  };

  const onSelectSortBy = (e: SelectChangeEvent) => {
    // TODO: Temporary check until we implement all sorts
    if (e.target.value !== 'progress') {
      setSortBy(e.target.value);
    }
  };

  const sortedIncomplete = () => {
    if (sortBy === 'progress') {
      return ([...activity.incomplete] as TrainingGroupPreview[]).sort((a, b) => (a.progress ?? 0) - (b.progress ?? 0));
    }

    return activity.incomplete;
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="h2" className={classes.title}>
        Your Activity
      </Typography>
      <ToggleButtonGroup
        className={classes.toggle}
        value={tab}
        exclusive
        onChange={(e, value) => handleTabChange(value)}
      >
        <ToggleButton value="curriculums" aria-label="list">
          Curriculums
        </ToggleButton>
        <ToggleButton value="courses" aria-label="list">
          Courses
        </ToggleButton>
        <ToggleButton value="evaluations" aria-label="list">
          Evaluations
        </ToggleButton>
        <ToggleButton value="exams" aria-label="list">
          Exams
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={classes.layout}>
        <div className={classes.columnLeft}>
          {(tab === 'courses' || tab === 'curriculums') && (
            <SelectedActivity id={selectedActivityId} groupChildType={groupChildType(tab)} />
          )}
          {(tab === 'evaluations' || tab === 'exams') && <SelectedTraining training={selectedTraining} />}

          <div className={classes.completedCourses}>
            <Typography className={classes.completedCoursesLabel} variant="h6">
              {`Showing ${activity.completed.length} completed ${capitalize(tab)}`}
            </Typography>
            <CompletedActivities
              activities={activity.completed}
              type={tab}
              selectedActivityId={selectedActivityId}
              setSelectedActivityId={setSelectedActivityId}
            />
          </div>
        </div>
        <div className={classes.columnRight}>
          <div className={classes.incompleteItemsHeader}>
            <Typography className={classes.incompleteItemsHeaderLabel} variant="h6">
              Incomplete items
            </Typography>
            <div className={classes.incompleteItemsHeaderOptions}>
              <Select value={sortBy} onChange={onSelectSortBy}>
                <MenuItem value="expirationDate"> Sort By: Assigned Date </MenuItem>
                <MenuItem value="assignedDate"> Sort By: Expiration Date </MenuItem>
                <MenuItem value="progress"> Sort By: Progress </MenuItem>
                <MenuItem value="assigned"> Sort By: Assigned </MenuItem>
                <MenuItem value="notStarted"> Sort By: Not Started </MenuItem>
              </Select>
            </div>
          </div>
          <div className={classes.incompleteItemsList}>
            <ScrollableList direction="y">
              {sortedIncomplete().map(item => (
                <ActivityCard key={`activity-card-${item.id}`} type={tab} item={item} />
              ))}
            </ScrollableList>
          </div>
        </div>
      </div>
    </div>
  );
}

type ActivityTab = 'courses' | 'evaluations' | 'curriculums' | 'exams';
