import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { sum } from '@sightgain/core/calculations';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import { Calendar as SCalendar } from '../../../reusables/scl';
import useCalendarEvents from '../../../reusables/useCalendarEvents';
import useExamEvents from '../../../reusables/useExamEvents';
import useRecentAchievements from '../../../reusables/useRecentAchievements';
import { curriculumService } from '../../../services';
import { CalendarEvent, ExamEvent, TrainingGroupType } from '../../../services/interfaces';
import AchievementsContainer from '../components/AchievementsContainer';
import ContentScheduler from '../components/ContentScheduler';
import CalendarEventItem from './CalendarEventItem';
import ExamEventItem from './ExamEventItem';
import Stats from './Stats';

const useStyles = makeStyles(() => ({
  gradient: {
    zIndex: -1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '80px',
    padding: '46px 51px 55px',
    opacity: 0.48,
    backgroundImage: 'linear-gradient(rgba(41, 75, 106, 0) 5%, rgba(39, 72, 101, 0.02) 50%, rgba(0, 0, 0, 0.51) 100%)',
  },
  header: {
    marginBottom: '39px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  headerLeft: {
    display: 'flex',
    gap: 26,
    flex: 1,
    maxWidth: '800px',
  },
  middleRow: {
    flexShrink: 1,
    display: 'flex',
    marginBottom: 30,
    maxWidth: '100%',
    alignItems: 'flex-end',
  },
  middleRowLeft: {
    width: 931,
    height: 560,
    marginRight: 12,
  },
  middleRowRight: {
    flex: 1,
    marginLeft: 12,
  },
  bottomRow: {
    flexshrink: 1,
    display: 'flex',
    maxWidth: '100%',
  },
  bottomRowLeft: {
    flex: 1,
    minWidth: 931,
  },
  bottomRowRight: {
    flex: 1,
    minWidth: 509,
    marginLeft: 24,
  },
  wrapper: {
    height: '888px',
    flex: 1,
  },
  items: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    height: 560,
    borderRadius: '6px',
    backgroundColor: 'rgba(255, 255, 255, 0.09)',
    overflow: 'hidden',
  },
  newItem: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 80,
    paddingRight: 80,
  },
  selectedItem: {
    position: 'relative',
    flex: 1,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#21bee3',
    width: 54,
    height: 54,
    cursor: 'pointer',
  },
  itemWrapper: {
    height: 506,
    overflow: 'auto',
  },
}));

// (CalendarEvent & { start: Date })[]
export default function Calendar() {
  const classes = useStyles();

  const [selected, setSelected] = useState<(CalendarEvent | ExamEvent)[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState(0);
  const [curriculums, setCurriculums] = useState(0);
  const [curMonthDuration, setCurMonthDuration] = useState(0);
  const [prevMonthDuration, setPrevMonthDuration] = useState(0);

  const { id } = useParams<{ id?: string }>();
  const userId = id ?? appStore.userId;

  const calendarEvents = useCalendarEvents(userId);
  const examEvents = useExamEvents(userId);
  const events = useMemo(
    () => [...calendarEvents.map(x => ({ ...x, start: parseISO(x.day) })), ...examEvents],
    [calendarEvents, examEvents],
  );
  const recentAchievements = useRecentAchievements(userId, 30);

  useAbort(
    () => {
      const curMonthStart = startOfMonth(selectedDate);
      const curMonthEnd = endOfMonth(selectedDate);
      const prevMonthStart = startOfMonth(new Date(selectedDate).setMonth(selectedDate.getMonth() - 1));
      const prevMonthEnd = endOfMonth(new Date(selectedDate).setMonth(selectedDate.getMonth() - 1));

      return Promise.all([
        curriculumService.studentActivity(userId, TrainingGroupType.course),
        curriculumService.studentActivity(userId, TrainingGroupType.curriculum),
        curriculumService.studentActivityTime({ student: userId, start: curMonthStart, end: curMonthEnd }),
        curriculumService.studentActivityTime({ student: userId, start: prevMonthStart, end: prevMonthEnd }),
      ]);
    },
    result => {
      setCourses(result[0].completed.length);
      setCurriculums(result[1].completed.length);
      setCurMonthDuration(Math.round(sum(result[2].map(({ durationSeconds }) => durationSeconds)) / 60));
      setPrevMonthDuration(Math.round(sum(result[3].map(({ durationSeconds }) => durationSeconds)) / 60));
    },
  );

  useAbort(
    () => events,
    result => {
      const selectedDateStr = format(selectedDate, 'yyyy-MM-dd');
      setSelected(events.filter(i => format(i.start, 'yyyy-MM-dd') === selectedDateStr));
    },
    [events],
  );

  const achievements = recentAchievements.map(a => a.name);

  const select = (date: Date) => {
    setSelectedDate(date);
    const dateStr = format(date, 'yyyy-MM-dd');
    const selectedEvents = events.filter(i => format(i.start, 'yyyy-MM-dd') === dateStr);
    setSelected(selectedEvents);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography variant="h2">Calendar</Typography>
        </div>
      </div>
      <div className={classes.middleRow}>
        <div className={classes.middleRowLeft}>
          <SCalendar
            scheduled={events.filter(e => e.status === 'scheduled').flatMap(s => s.start)}
            completed={events.filter(e => e.status === 'completed').flatMap(c => c.start)}
            onClick={select}
            large
            autoWidth
            hover
          />
        </div>
        <div className={classes.middleRowRight}>
          <div className={classes.items}>
            {selected.length > 0 && (
              <div className={classes.selectedItem}>
                <div className={classes.itemWrapper}>
                  {selected.map(item => {
                    if ('exam' in item) {
                      return <ExamEventItem item={item} key={item.id} />;
                    }

                    return <CalendarEventItem item={item} key={item.id} />;
                  })}
                </div>
                <div className={classes.gradient} />
              </div>
            )}
            {!selected.length && (
              <div className={classes.newItem}>
                <Typography align="center">
                  Select a scheduled item from the calendar, or schedule a new course
                </Typography>
                <div className={classes.gradient} />
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <Typography style={{ marginRight: 15 }}>Add New Item</Typography>
              <div
                onClick={() => setOpen(true)}
                data-testid="add-new-item"
                role="presentation"
                className={classes.button}
              >
                <FontAwesomeIcon icon={['fal', 'plus']} size="lg" />
              </div>
              {open && <ContentScheduler date={selectedDate} setOpen={setOpen} attendee={userId} />}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.bottomRow}>
        <div className={classes.bottomRowLeft}>
          <Stats
            achievements={achievements.length}
            courses={courses}
            curriculums={curriculums}
            mins={curMonthDuration}
            minsLastMonth={prevMonthDuration}
          />
        </div>
        <div className={classes.bottomRowRight}>
          <AchievementsContainer achievements={achievements} title="Achievements earned last 30 days" />
        </div>
      </div>
    </div>
  );
}
