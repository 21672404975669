import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExportEvaluationButton from './ExportEvaluationButton';
import DeleteEvaluationButton from './DeleteEvaluationButton';
import CloneEvaluationButton from './CloneEvaluationButton';
import { MgrListItem } from '../interfaces';
import useEvaluationsWatcher from '../useEvaluationsWatcher';
import { CircularProgress, Radio } from '@mui/material';
import LastRunText from './LastRun';
import appStore from '../../../../../../AppStore';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

interface EvaluationListProps {
  search?: string;
  activeId?: string;
  isExams?: boolean;
  onSelect: (item: MgrListItem) => unknown;
  onClone: (item: MgrListItem) => unknown;
}

export default function EvaluationList({ search, activeId, isExams, onSelect, onClone }: EvaluationListProps) {
  const evaluations = useEvaluationsWatcher(appStore.SIP, isExams);

  if (!evaluations || typeof evaluations === 'string') {
    return (
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="inherit" disableShrink />
      </div>
    );
  }

  let filtered = evaluations;

  if (search) {
    filtered = evaluations.filter(
      d => d.name.toLowerCase().includes(search) || (d.vendorId && d.vendorId.toLowerCase().includes(search)),
    );
  }

  const Row = ({ index, style }: ListChildComponentProps) => {
    const item = filtered[index];
    return (
      <ListItem
        key={`${item.vendorId}-${item.vendor}`}
        id={item.vendorId}
        alignItems="flex-start"
        className="listItem"
        button
        divider
        style={style}
        onClick={e => onSelect(item)}
        selected={item.id === activeId}
      >
        <Radio readOnly checked={item.id === activeId} />
        <ListItemText
          style={{ flexGrow: 1 }}
          className="listItemText"
          primary={item.name}
          primaryTypographyProps={{ style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
          secondary={
            <>
              {item.vendorId}
              <br />
              <LastRunText lastRun={item.lastRun} />
            </>
          }
        />
        <div style={{ minWidth: 150, textAlign: 'right' }}>
          <ExportEvaluationButton item={item} isExam={isExams} />
          <CloneEvaluationButton onClone={() => onClone(item)} />
          {(item.isCustom || isExams) && <DeleteEvaluationButton title={item.name} id={item.id} isExam={isExams} />}
        </div>
      </ListItem>
    );
  };

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {filtered.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              height={height - 100} // Adjust the height based on your requirements
              width={width} // Set to 100% or a specific width
              itemSize={100} // Adjust item height based on your ListItem height
              itemCount={filtered.length}
              overscanCount={5} // Number of items to render outside of the visible area for smoother scrolling
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      ) : (
        <ListItem>
          <ListItemText className="listItemText">
            <p style={{ textAlign: 'center' }}>No results</p>
          </ListItemText>
        </ListItem>
      )}
    </div>
  );
}
