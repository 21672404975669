import { FormEvent, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clone } from '@sightgain/core';
import Modal from '../../../../reusables/Modal';
import SearchBar from '../../../../reusables/SearchBar';
import { Framework, FrameworkGroup } from '../../../../services/interfaces';

function replaceObjectByName(array: FrameworkGroup[], nameToMatch: string, newObject: FrameworkGroup) {
  const index = array.findIndex(obj => obj.name === nameToMatch);
  if (index !== -1) {
    array[index] = newObject;
  } else {
    throw new Error(`Object with name '${nameToMatch}' not found`);
  }
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    minWidth: 500,
  },
  title: {
    padding: '0px 0px 28px 0px',
  },
  items: {
    height: 300,
    overflowY: 'auto',
    width: 400,
  },
});

interface TechniqueModalProps {
  open?: boolean;
  onClose: () => void;
  onUpdate: (draft: Framework) => void;
  framework: Framework;
  frameworkDraft: Framework;
  tacticName: string;
}

export default function TechniqueModal({
  open,
  onClose,
  onUpdate,
  framework,
  frameworkDraft,
  tacticName,
}: TechniqueModalProps) {
  const classes = useStyles();
  const [tacticSearch, setTacticSearch] = useState<string>('');
  const [techniqueSearch, setTechniqueSearch] = useState<string>('');
  const [selectedTactic, setSelectedTactic] = useState<FrameworkGroup | undefined>(
    framework.groups.find(x => x.name === tacticName) ?? undefined,
  );

  const [state, setState] = useState(clone(frameworkDraft));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const tactic = state.groups.find(x => x.name === selectedTactic?.name);
    // Find the original tactic in the framework prop
    const originalTactic = framework.groups.find(x => x.name === selectedTactic?.name);

    if (!tactic || !originalTactic) {
      return;
    }

    if (checked) {
      // Find the item in the original framework to maintain any associated data
      const item = originalTactic.items.find(x => x.name === name);
      if (item) {
        tactic.items.push(clone(item));
      }
    } else {
      tactic.items = tactic.items.filter(x => x.name !== name);
    }

    // Reorder items based on the original order in the framework
    const originalOrder = originalTactic.items.map(item => item.name);
    tactic.items.sort((a, b) => originalOrder.indexOf(a.name) - originalOrder.indexOf(b.name));

    // Update the state maintaining the order
    replaceObjectByName(state.groups, tactic.name, tactic);
    setState(clone(state));
  };

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();
    onUpdate(state);
  };

  const filteredTactics = framework.groups.filter(x => x.name.includes(tacticSearch));
  const filteredTechniques = selectedTactic?.items.filter(x => x.name.includes(techniqueSearch)) ?? [];

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleSave}
      actions={[
        { text: 'Update', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container}>
        <div style={{ flex: 1 }}>
          <div className={classes.title}>
            <Typography variant="h5">Select Tactic</Typography>
          </div>
          <div>
            <SearchBar placeholder="Search Tactic" onChange={setTacticSearch} />
          </div>
          <div className={classes.items}>
            <RadioGroup defaultValue={tacticName}>
              {filteredTactics.map(group => (
                <FormControlLabel
                  style={{ textTransform: 'capitalize' }}
                  key={group.name}
                  value={group.name}
                  control={<Radio />}
                  label={group.name}
                  onClick={() => setSelectedTactic(group)}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div className={classes.title}>
            <Typography variant="h5">Select Techniques</Typography>
          </div>
          <div>
            <SearchBar placeholder="Search Techniques" onChange={setTechniqueSearch} />
          </div>
          <div className={classes.items}>
            <FormGroup>
              {filteredTechniques.map(item => (
                <FormControlLabel
                  style={{ textTransform: 'capitalize' }}
                  key={item.name}
                  control={
                    <Checkbox
                      checked={
                        !!state.groups.find(x => x.name === selectedTactic?.name)?.items.find(y => y.name === item.name)
                      }
                      onChange={handleChange}
                      name={item.name}
                    />
                  }
                  label={item.name}
                />
              ))}
            </FormGroup>
          </div>
        </div>
      </div>
    </Modal>
  );
}
