import { AssessmentProvider } from '../components/AssessmentContext';
import FrameworkScoring from './FrameworkScoring';

export default function FrameworkScoringContainer() {
  return (
    <AssessmentProvider>
      <FrameworkScoring />
    </AssessmentProvider>
  );
}
