import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { User, UserGroup, UserGroupInput, UserInput, UserSignUpInput } from './interfaces';
import ServiceBase from './ServiceBase';

const typeUserGroup = `
  {
    id
    name
    description
  }
`;

const typeUser = `
  {
    id
    active
    name
    email
    aliases
    group
    roles
    requirePasswordReset
    preferences {
      name
      value
    }
    status
    createdAt
    updatedAt
    online
  }
`;

export class UsersService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['updated', 'created', 'updatedAt', 'createdAt']));
  }

  /**
   * Returns a single user
   */
  async find(id: string, fields = typeUser): Promise<User> {
    const query = gql`query User($id: ID!) { user(id: $id) ${fields} }`;
    const { user } = await this.graphql(query, { id }, 'network-only');
    return user;
  }

  /**
   * Returns a single user
   */
  async sso(id: string, token: string, fields = typeUser): Promise<User> {
    const query = gql`mutation SSO($id: ID!, $token: String!) { sso(id: $id, token: $token) ${fields} }`;
    const { sso } = await this.graphql(query, { id, token }, 'network-only');
    return sso;
  }

  /**
   * Returns the users from the database
   */
  async list(includeDisabled = false, fields = typeUser): Promise<User[]> {
    const query = gql`
      query Users($includeDisabled: Boolean) {
        users(includeDisabled: $includeDisabled) ${fields}
      } 
    `;

    const { users } = await this.graphql(query, { includeDisabled }, 'network-only');
    return users;
  }

  /**
   * Returns the user groups
   */
  async listGroups(fields = typeUserGroup): Promise<UserGroup[]> {
    const query = gql`query UserGroups { userGroups ${fields} }`;
    const { userGroups } = await this.graphql(query);
    return userGroups;
  }

  /**
   * Creates or updates a user group
   * @returns
   */
  async saveGroup(input: UserGroupInput, fields = typeUserGroup): Promise<UserGroup> {
    const query = gql`mutation SaveGroup($input: UserGroupInput!) { saveGroup(input: $input) ${fields} }`;
    const { saveGroup } = await this.graphql(query, { input });

    // remove the existing group
    if (input.id) {
      this.removeGQLCache(
        gql`query UserGroups { userGroups ${fields} }`,
        undefined,
        'userGroups',
        v => v.id === input.id,
      );
    }

    // add the new group
    this.appendGQLCache(gql`query UserGroups { userGroups ${fields} }`, undefined, 'userGroups', saveGroup);

    return saveGroup;
  }

  /**
   * Creates or updates an existing user
   */
  async save(input: UserInput, fields = typeUser): Promise<User> {
    if (input.id && !input.password) {
      delete input.password;
    }

    const query = gql`mutation SaveUser($input: UserDataInput!) { saveUser(input: $input) ${fields}}`;
    const { saveUser } = await this.graphql(query, { input });
    return saveUser;
  }

  /**
   * Saves multiple users at once
   */
  async saveUsers(input: UserInput[], fields = typeUser): Promise<User[]> {
    const query = gql`mutation SaveUsers($input: [UserDataInput!]!) { saveUsers(input: $input) ${fields}}`;
    const { saveUsers } = await this.graphql(query, { input });
    return saveUsers;
  }

  /**
   * Self sign up call
   */
  async signup(input: UserSignUpInput): Promise<string> {
    const query = gql`
      mutation Signup($input: UserSignUpInput!) {
        signup(input: $input)
      }
    `;
    const { signup } = await this.graphql(query, { input });
    return signup;
  }

  /**
   * Enable reporting API configuration
   */
  async enableReporting(): Promise<void> {
    const query = gql`
      mutation EnableReporting {
        enableReporting
      }
    `;
    const { enableReporting } = await this.graphql(query);
    return enableReporting;
  }

  /**
   * Disables reporting API configuration
   */
  async disableReporting(): Promise<void> {
    const query = gql`
      mutation DisableReporting {
        disableReporting
      }
    `;
    const { disableReporting } = await this.graphql(query);
    return disableReporting;
  }

  /**
   * Return reporting API user client secret
   * @returns client secret
   */
  async reportingApiToken(): Promise<string> {
    const query = gql`
      query ReportingApiToken {
        reportingApiToken
      }
    `;
    const { reportingApiToken } = await this.graphql(query, {}, 'network-only');
    return reportingApiToken;
  }
}

const usersService = new UsersService();
export default usersService;
