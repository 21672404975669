import {
  Assessor,
  AssessorScore,
  CreateThreatIntelInput,
  Framework,
  ProductResult,
  ScoredFramework,
  ScoredFrameworkItem,
  SecurityZone,
  TacticScore,
  Test,
  TestResult,
  ThreatGroup,
  ThresholdColors,
} from '../../../services/interfaces';
import { SetUserPreference } from '../../../services/UserPreferencesService';
import { ConfigValidationItem } from './configValidation/interfaces';
import { ThreatScoreModalProperties } from './modals/ThreatScoreModal';

export enum TabView {
  SINGLE,
  COMPARE,
  CAPABILITY_MATURITY,
  CONFIG_VALIDATION,
}

export enum FrameworkType {
  ATTACK = 'attack',
}

export enum PageTab {
  RISK_POSTURE = 'Risk Posture',
  CONTROL_EFFICACY = 'Control Efficacy',
  CONFIG_VALIDATION = 'Config. Validation',
  THREAT_INTELLIGENCE = 'Threat Intelligence',
  ASSESSOR_SCORE = 'Assessor Score',
  COMPOUND = 'Compound Score',
  CAPABILITY_MATURITY = 'Capability Maturity',
}

export enum AggregationMethod {
  AVERAGE = 'AVERAGE',
  LOWEST = 'LOWEST',
}

export enum ScoreType {
  ASSESSOR = 'ASSESSOR',
  CAPMIT = 'CAPMIT',
  HEAT = 'HEAT',
  NONE = 'NONE',
  RAW = 'RAW',
  RELATIVE = 'RELATIVE',
  SECMIT = 'SECMIT',
  COMPOUND = 'COMPOUND',
  CAPABILITY_MATURITY = 'CAPABILITY_MATURITY',
}

export enum TacticGrade {
  UNTESTED = 'UNTESTED',
  PASS = 'PASS',
  HIGH = 'HIGH',
  LOW = 'LOW',
  FAIL = 'FAIL',
}

export type ScoreTrend = Array<number | null>;

export type SetFrameworkModalProps = React.Dispatch<React.SetStateAction<FrameworkModalProps>>;
export type TriggerFrameworkModal = (tactic: ScoredFrameworkItem, columnName?: string) => void;
export type TriggerTileDetailModal = (tactic: ScoredFrameworkItem, startTab?: number, refresh?: boolean) => void;

export type FrameworkModalProps = {
  open?: boolean;
  title?: string;
  data?: Array<Assessor | TestResult>;
  tacticId?: string;
  testIds?: string[];
};

export interface TacticCompareModalParital {
  open: boolean;
  tactic?: ScoredFrameworkItem;
  columnName?: string;
}

export interface TacticCompareModalProps extends TacticCompareModalParital {
  onClose: () => void;
  day1: string;
  day2: string;
}

export interface TileDetailProps {
  open: boolean;
  isReadonly?: boolean;
  isTestTab?: boolean;
  isSubItem?: boolean;
  title?: string;
  tacticId?: string;
  testIds?: string[];
  control?: ConfigValidationItem;
  onClose?: (rescore?: boolean) => unknown;
  refresh?: boolean;
  refreshScoredItem?: (identifier: string) => Promise<void>;
  startTab?: number;
  ignoredEvaluations?: string[];
}

export interface CompareModalProperties {
  open: boolean;
  day1: string;
  day2: string;
}

export interface FrameworkDays {
  day1: string;
  day2: string;
}

export interface FrameworkContextInitialState {
  threatGroups: ThreatGroup[];
  // TODO: just use an array instead of separate vars?
  scoredFramework: ScoredFramework | null; // 'Today'
  scoredFrameworkDay1: ScoredFramework | null; // 'Comparison Day 1
  scoredFrameworkDay2: ScoredFramework | null; // 'Comparison Day 2
  scoredFrameworkDays: FrameworkDays[]; // Available comparisons
  settings: FrameworkScoringSettings;
  thresholdColors: ThresholdColors;
  assessorThreshold: number;
  backgroundColor: (score?: TacticScore) => string;
  compoundWeights: number;
  createThreatIntel: (input: CreateThreatIntelInput) => Promise<void>;
  currentFramework: FrameworkListItem;
  handleControlOnClick: (control: ScoredFrameworkItem, columnName?: string, isSubItem?: boolean) => void;
  setSettings: (value: SetUserPreference<FrameworkScoringSettings>) => void;
  setThreatGroups: React.Dispatch<React.SetStateAction<ThreatGroup[]>>;
  setModalThreatGroupProperties: React.Dispatch<React.SetStateAction<{ open: boolean }>>;
  setModalThreatScoreProperties: React.Dispatch<React.SetStateAction<ThreatScoreModalProperties>>;
  setModalCompareProperties: React.Dispatch<React.SetStateAction<CompareModalProperties>>;
  setScoredFrameworkDay1: React.Dispatch<React.SetStateAction<ScoredFramework | null>>;
  setScoredFrameworkDay2: React.Dispatch<React.SetStateAction<ScoredFramework | null>>;
  setScoredFrameworkDays: React.Dispatch<React.SetStateAction<FrameworkDays[]>>;
  securityZones: number[];
  setSecurityZones: (val: number[]) => void;
  observedZones: SecurityZone[];
  setObservedZones: (val: SecurityZone[]) => void;
}

export interface FrameworkScoringSettings {
  aggregationMethod: AggregationMethod;
  threatGroup: string;
  addButton: boolean;
}

export interface FrameworkTactic {
  actionIds: string[];
  tacticId: string[];
  techniqueId: string[];
  title: string;
}

export interface TileScore {
  testScore: number | null;
  postureScore: number | null;
  threatScore: number | null;
  testTrend: ScoreTrend;
  assessorScore: AssessorScore | AssessorScore[] | null;
}

export type ScoringWeight = [number, number];

export type TestResultHistoryItemProduct = Pick<ProductResult, 'prevented' | 'detected'>;
export type TestResultHistoryItemTest = Pick<Test, 'name' | 'description' | 'vendorId'>;
type TestResultFields = 'id' | 'testId' | 'description' | 'name' | 'createdAt' | 'vendor';
export type TestResultHistoryItem = Pick<TestResult, TestResultFields> & {
  products: TestResultHistoryItemProduct[];
};

export type AssessorHistoryItem = Pick<Assessor, 'id' | 'createdAt' | 'comments' | 'score' | 'tacticId' | 'files'> & {
  assessor: { name: string };
};

export type FrameworkListItem = Pick<Framework, 'id' | 'name' | 'title' | 'version'>;

export type DayCompare = (day1: string, day2: string) => void;
