import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { AnalystInput } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  container: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#0b0c12',
    borderRadius: 6,
    overflow: 'hidden',
    width: 692,
    height: 475,
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '29px 29px 18px 29px',
  },
  bottomRow: {
    padding: '22px 29px 20px 29px',
    borderTop: 'solid 2px #1F2336',
    overflowY: 'overlay' as any,
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    marginBottom: 14,
  },
  field: {
    marginBottom: 33,
  },
}));

function UnmatchedCard({ input }: UnmatchedCardProps) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <Typography variant="h5">Unmatched Event</Typography>
      </div>
      <div className={classes.bottomRow}>
        <Typography variant="h5" className={classes.label}>
          Event
        </Typography>
        <Typography variant="body1" className={classes.field}>
          {input.event}
        </Typography>
        <Typography variant="h5" className={classes.label}>
          Notes
        </Typography>
        <Typography variant="body1" className={classes.field}>
          {input.description}
        </Typography>
      </div>
    </div>
  );
}

interface UnmatchedCardProps {
  input: AnalystInput;
}

export default UnmatchedCard;
