import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { escape } from '@sightgain/core/strings';
import { useAbort } from '../../../../../effects';
import { ModalStore, useModalStore } from '../../../../reusables/BetterModal';
import { jobsService } from '../../../../services';
import assessmentsService from '../../../../services/AssessmentsService';
import frameworksService from '../../../../services/FrameworksService';
import { FrameworkItemWithScores, FrameworkWithScores } from '../../../../services/interfaces';
import { useAssessmentContext } from '../../components/AssessmentContext';
import Dialog from './components/Dialog';
import ItemDetails from './components/ItemDetails';
import ObjectiveBox from './components/ObjectiveBox';
import ScoreBox from './components/ScoreBox';
import ThresholdForm from './components/ThresholdForm';
import FrameworkItemScores from './frameworkItemScores';
import { MaturityLevel, Objective } from './interface';
import ManualAssessmentForm from './ManualAssessmentForm';

const useStyles = makeStyles(theme => ({
  closeDialog: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  itemName: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  objectives: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}));

interface ListObjectivesProps {
  objectives: Objective[];
  onObjective: (objective: Objective) => void;
  onCSV: (objective: Objective) => Promise<Array<Record<string, string | number | boolean | Date>>>;
}

function ListObjectives({ objectives, onObjective, onCSV }: ListObjectivesProps) {
  return (
    <>
      {objectives.map(obj => (
        <Grid item key={obj.name} lg={4}>
          <ObjectiveBox objective={obj} onObjective={onObjective} onCSV={onCSV} />
        </Grid>
      ))}
    </>
  );
}

type MaturityLevelProps = [MaturityLevel, (value: MaturityLevel) => void];

interface CapabilityDetailsProps {
  framework: FrameworkWithScores;
  item: FrameworkItemWithScores;
  maturityLevel: MaturityLevelProps;
}

export default function CapabilityDetails({ framework, item, maturityLevel }: CapabilityDetailsProps) {
  const classes = useStyles();
  const { assessment, isReadonly } = useAssessmentContext();
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [detailsModelOpen, setDetailsModelOpen] = useState(false);
  const [objectiveManualAssessment, setObjectiveManualAssessment] = useState<Objective>({
    id: '',
    name: '',
    description: '',
    identifier: '',
    maturityLevel: undefined,
    scores: new FrameworkItemScores({ scores: [] }, MaturityLevel.TARGET),
    questions: [],
    answers: [],
    evidence: [],
    notes: [],
  });
  const createModal = useModalStore((s: ModalStore) => s.createModal);
  const ThresholdModal = createModal('itemThresholdModal');
  const ManualAssessmentModal = createModal('itemManualAssessmentModal');
  const itemScores = new FrameworkItemScores(item, maturityLevel[0]);

  useAbort(
    async () => {
      // get the sub-items for the currentItem
      const fwItem = await frameworksService.findItem(item.identifier, framework.name, framework.version);
      // get details for each sub-item
      if (!fwItem.sub.length) {
        return [];
      }
      return Promise.all(
        fwItem.sub.map(subItem => {
          return assessmentsService.objective(assessment.id, subItem.id);
        }),
      );
    },
    results => {
      const newObjectives = results.map(r => ({
        id: r.frameworkItem.id,
        name: r.frameworkItem.name,
        description: r.frameworkItem.description,
        maturityLevel: r.frameworkItem.maturityLevel,
        scores: new FrameworkItemScores(r.frameworkItem, maturityLevel[0]),
        identifier: r.frameworkItem.identifier,
        questions: r.assessorInput.length ? r.assessorInput[0].questions : [],
        answers: r.assessorInput.length ? r.assessorInput[0].answers : [],
        evidence: r.assessorInput.length ? r.assessorInput[0].evidence : [],
        notes: r.assessorInput.length ? r.assessorInput[0].notes : [],
      }));
      setObjectives(newObjectives);

      // if an assesment has changed because of an upload, we need to update the selected obejctive
      if (objectiveManualAssessment.id) {
        const updateObjectiveManualAssessment = newObjectives.find(x => x.id === objectiveManualAssessment.id);
        if (updateObjectiveManualAssessment) {
          setObjectiveManualAssessment(updateObjectiveManualAssessment);
        }
      }
    },
    [assessment],
  );

  const filteredObjectives = useMemo(() => {
    if (maturityLevel[0] === MaturityLevel.ALL) {
      return objectives;
    }
    return objectives.filter(o => o.maturityLevel === maturityLevel[0]);
  }, [maturityLevel, objectives]);

  let scores;
  switch (maturityLevel[0]) {
    case MaturityLevel.TARGET:
      scores = [
        itemScores.itemOverallTargetMaturityScore,
        itemScores.itemAutomatedTargetMaturityScore,
        itemScores.itemSelfScoreTargetMaturityScore,
      ];
      break;
    case MaturityLevel.ADVANCED:
      scores = [
        itemScores.itemOverallAdvancedMaturityScore,
        itemScores.itemAutomatedAdvancedMaturityScore,
        itemScores.itemSelfScoreAdvancedMaturityScore,
      ];
      break;
    default:
      scores = [
        itemScores.itemOverallMaturityScore,
        itemScores.itemAutomatedMaturityScore,
        itemScores.itemSelfScoreMaturityScore,
      ];
  }

  const handleCsv = async (objective: Objective) => {
    const metadata = objective.scores.subItemAutomatedMaturityScore.metadata;
    const testIds = metadata.testResultIds;
    const testResults = await jobsService.list(
      testIds,
      { useInternalIds: true },
      {},
      `{       
      testId
      name
      alerted
      detected
      prevented
      stage {
        job {
          vendorId
          isExcluded
          name
          createdAt
        }
      }
    }`,
    );

    const data = testResults.map(t => ({
      testId: t.testId,
      testName: escape(t.name),
      alerted: t.alerted,
      detected: t.detected,
      prevented: t.prevented,
      jobVendorId: t.stage.job.vendorId,
      jobName: escape(t.stage.job.name),
      jobIsExcluded: t.stage.job.isExcluded,
      jobCreatedAt: t.stage.job.createdAt,
    }));

    return data;
  };

  return (
    <div id="capability-maturity" data-testid="capability-maturity-details">
      <Grid container direction="row" spacing={4}>
        {/* Item title & description */}
        <Grid item xs={7}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography className={classes.itemName} variant="h2" onClick={() => setDetailsModelOpen(true)}>
                {item.identifier} {item.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" color="subduedText">
                {item.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Score Boxes */}
        <Grid item xs={5}>
          <Grid container direction="row" spacing={2} wrap="nowrap">
            {[
              { title: 'Overall Maturity Score', infoText: 'Combined Average of Automated and Manual Scores.' },
              { title: 'Automated Maturity Score', infoText: 'Averages the automated activity scores.' },
              {
                title: 'Manual Assessment Maturity Score',
                infoText: 'The average of each manual assessment average.',
              },
            ].map((score, scoreIndex) => (
              <Grid key={`score-${score.title}`} item>
                <ScoreBox title={score.title} value={scores[scoreIndex]?.score} infoText={score.infoText} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ margin: '50px 0' }} />

      {/* List Objectives (sub-items) */}
      <Grid container spacing={2}>
        <ListObjectives objectives={filteredObjectives} onObjective={setObjectiveManualAssessment} onCSV={handleCsv} />
      </Grid>

      {/* Display details of item */}
      <Dialog open={detailsModelOpen}>
        <div className={classes.objectives}>
          <ItemDetails objectives={objectives} />
        </div>
        <div className={classes.closeDialog}>
          <Button onClick={() => setDetailsModelOpen(value => !value)} color="inherit">
            <FontAwesomeIcon icon={['far', 'times-circle']} size="2x" color="#5E6F82" />
          </Button>
        </div>
      </Dialog>

      {/* Display Manual Assessment form */}
      <ManualAssessmentModal>
        <ManualAssessmentForm objective={objectiveManualAssessment} isReadonly={isReadonly} />
      </ManualAssessmentModal>

      {/* Edit minimum thresholds */}
      <ThresholdModal>
        <ThresholdForm assessment={assessment} framework={framework} />
      </ThresholdModal>
    </div>
  );
}
