import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useAbort } from '../../../../../effects';
import { curriculumService, liveFireTrainingService } from '../../../../services';
import TitleAndViewAll from '../../components/TitleAndViewAll';
import LeaderBoardPreview from './LeaderBoardPreview';
import LeaderBoard from './LeaderBoard';
import { AnalystScore } from './interfaces';
import { scoreData } from './score';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 24,
  },
  topRow: {
    marginBottom: 29,
    display: 'none',
  },
  middleRow: {
    flexShrink: 1,
    display: 'flex',
    marginBottom: 30,
    maxWidth: '100%',
    maxHeight: 475,
    gap: 25,

    '& > div': {
      flex: 1,
    },
  },
}));

export default function Instructor() {
  const classes = useStyles();
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [byInstructorScore, setByInstructorScore] = useState(false);
  const [scores, setScores] = useState<AnalystScore[]>([]);

  useAbort(
    () =>
      Promise.all([
        liveFireTrainingService.list(
          { statuses: ['completed'], hideRetakes: true },
          { limit: 1000 },
          '{ id analyst { id name email } instructorScore score }',
        ),
        curriculumService.students('{ id name email achievements curriculums courses }'),
      ]),
    ([trainings, students]) => {
      setScores(scoreData(students, trainings));
    },
    [],
  );

  if (showLeaderboard) {
    return (
      <LeaderBoard scores={scores} setShowLeaderBoard={setShowLeaderboard} byInstructorScore={byInstructorScore} />
    );
  }

  const handleViewAll = (fromInstructorScore = false) => {
    setByInstructorScore(fromInstructorScore);
    setShowLeaderboard(true);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h2">Your Instructor Overview</Typography>
      </div>
      <div className={classes.middleRow}>
        <div>
          <TitleAndViewAll title="Top 10 Analyst Leaderboard by Composite Score" onClick={() => handleViewAll()} />
          <LeaderBoardPreview scores={scores} />
        </div>
        <div>
          <TitleAndViewAll
            title="Top 10 Analysts Leaderboard by Instructor Score"
            onClick={() => handleViewAll(true)}
          />
          <LeaderBoardPreview scores={scores} byInstructorScore />
        </div>
      </div>
    </>
  );
}
