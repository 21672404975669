import { DataGrid as MuiDataGrid, GridAlignment, GridCellParams } from '@mui/x-data-grid';
import './index.css';
import PassFail from './PassFail';

export const numberColumn = {
  type: 'number',
  align: 'left' as GridAlignment,
  headerAlign: 'left' as GridAlignment,
};

type BoolValueParams = GridCellParams<any, boolean | undefined>;
export const renderBoolValue = ({ value }: BoolValueParams) => <PassFail pass={value} />;

type DateValueParams = GridCellParams<any, Date | string | number>;
export const renderDate = ({ value }: DateValueParams) => {
  if (!value) {
    return '';
  }
  const date = new Date(value);
  return `${date.toLocaleDateString('en-US')} | ${date.toLocaleTimeString('en-US')}`;
};

export default function DataGrid({ rows, columns, startingPageSize = 5 }: DataGridProps) {
  return <MuiDataGrid autoHeight hideFooterSelectedRowCount rows={rows} columns={columns} disableRowSelectionOnClick />;
}

type DataGridProps = {
  startingPageSize: number;
  rows: any[];
  columns: any;
};
