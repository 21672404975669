import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { ReactNode } from 'react';

interface DownloadLinkProps {
  props?: ButtonBaseProps;
  download: () => Promise<string>;
  fileName: string;
  mimeType: string;
  children?: ReactNode;
}

export default function DownloadLink({ props = {}, download, mimeType, fileName, children }: DownloadLinkProps) {
  const handleClick = async () => {
    const b64 = await download();
    const chars = atob(b64);
    const nums = [...Array(chars.length).keys()].map(n => chars.charCodeAt(n));
    const bytes = new Uint8Array(nums);
    const blob = new Blob([bytes], { type: mimeType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <ButtonBase {...props} onClick={handleClick}>
      {children ?? fileName}
    </ButtonBase>
  );
}
