import { useState } from 'react';
import { AddSharp } from '@mui/icons-material';
import { Button, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFilterContext } from './FilterContext';
import FilterControls from './FilterControls';
import { DateFilter, NumericFilter, SelectFilter, StringFilter } from './FilterTypes';
import BooleanFilter from './FilterTypes/BooleanFilter';
import { FilterResult, FilterSource, FilterType, OperationType, ValueType } from './interfaces';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    padding: '0 12px',
  },
  popover: {
    padding: 20,
    display: 'flex',
    gap: 32,
    background: '#303F52',
  },
}));

interface FilterButtonProps {
  sources: FilterSource[];
}

export default function FilterButton({ sources }: FilterButtonProps) {
  const classes = useStyles();
  const { setFilters } = useFilterContext();
  const [selectedName, setSelectedName] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterComponent, setFilterComponent] = useState<JSX.Element | null>(null);

  const [newFilter, setNewFilter] = useState<FilterResult>(() => ({
    name: '',
    description: '',
    filter: () => false,
    value: '',
  }));

  const handleFilterChange = (
    filterFunction: (item: any) => boolean,
    value: ValueType,
    name: string,
    description: string,
    operation?: OperationType,
  ) => {
    setNewFilter(prevFilter => ({
      ...prevFilter,
      filter: filterFunction,
      value,
      name,
      description,
      operation,
    }));
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterComponent(null);
    setSelectedName(null);
  };

  const handleSelect = (source: FilterSource) => {
    setSelectedName(source.name);

    const filterTypes: Record<FilterType, JSX.Element> = {
      string: <StringFilter onChange={handleFilterChange} source={source} />,
      select: <SelectFilter onChange={handleFilterChange} source={source} menuItems={source.menuItems} />,
      numeric: <NumericFilter onChange={handleFilterChange} source={source} />,
      boolean: <BooleanFilter onChange={handleFilterChange} source={source} />,
      date: <DateFilter onChange={handleFilterChange} source={source} />,
    };

    return setFilterComponent(filterTypes[source.filterType] || null);
  };

  const open = !!anchorEl;
  const id = open ? 'filter-popover' : undefined;

  const handleApply = () => {
    setFilters(prev => [...prev.filter(f => f.name !== newFilter.name), newFilter]);
    handleClose();
  };

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
        style={{ width: 140, display: 'flex', gap: 8 }}
      >
        Add Filter <AddSharp />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ marginTop: 8 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.popover}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {sources.map(source => (
              <Button
                key={source.name}
                onClick={() => handleSelect(source)}
                className={classes.button}
                color={selectedName === source.name ? 'secondary' : 'primary'}
              >
                {source.title}
              </Button>
            ))}
          </div>
          {filterComponent && (
            <FilterControls onCancel={handleClose} onApply={handleApply}>
              {filterComponent}
            </FilterControls>
          )}
        </div>
      </Popover>
    </>
  );
}
