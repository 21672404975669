import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { escape } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import CsvDownload from '../../../reusables/CsvDownload';
import { FilterProvider } from '../../../reusables/Filter';
import { Tabs } from '../../../reusables/scl';
import { useSetting } from '../../../reusables/useSetting';
import { jobsService } from '../../../services';
import { Role } from '../../../services/interfaces';
import EvaluationManager from './Manager';
import { MgrListItem } from './Manager/interfaces';
import NewEvaluationButtons from './NewEvaluationButtons';
import RecentlyRunEvaluations from './RecentlyRunEvaluations';

type EvaluationType = 'live-tests' | 'live-fire-training' | 'exam' | 'run-evaluation' | 'run-exam';

const TABS = new Map([
  ['default', 'All Live Fire'],
  ['live-tests', 'Live Test'],
  ['live-fire-training', 'Live Fire Training'],
  ['exam', 'Exam'],
  ['run-evaluation', 'Run Evaluation'],
  ['run-exam', 'Run Exam'],
]);

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
  content: {
    height: 'calc(100% - 230px)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 14,
    paddingTop: 14,
  },
  menu: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
}));

const jobFields = `
{
  createdAt
  vendorId
  name
  evaluationId
  status
  isExcluded
  stages {
    testResults {
      createdAt
      vendorId
      name
      tags
      prevented
      detected
      events {
        ... on HostEvent { id }
        ... on IntegrationEvent { id} 
      }
    }
  }
}
`;

function tabIndex(availableTabs: Map<string, string>, key: string) {
  return Math.max(0, [...availableTabs.keys()].indexOf(key));
}

function tabPath(availableTabs: Map<string, string>, index: number) {
  return [...availableTabs.keys()][index].replace('default', '') ?? '';
}

export default function Evaluations() {
  const classes = useStyles();
  const vendor = useSetting('sip', '', (v: string) => v);
  const { type = '' } = useParams<{ type: EvaluationType | undefined }>();
  const [selected, setSelected] = useState<MgrListItem>();
  const [cloned, setCloned] = useState<MgrListItem>();
  const [evalSearchValue, setEvalSearchValue] = useState('');
  const [examSearchValue, setExamSearchValue] = useState('');

  const availableTabs = useMemo(() => {
    switch (appStore.SIP) {
      case 'picus':
        // TODO: Add run-evaluation when pagination is fixed
        // TODO: Add live-tests and live-fire-training when logs are available
        return new Map([...TABS.entries()].filter(([key]) => key === 'default'));
      default:
        return TABS;
    }
  }, [appStore.SIP]);

  const history = useHistory();
  const selectedTab = useMemo(() => tabIndex(availableTabs, type), [availableTabs, type]);

  const handleTabSelect = useCallback(
    (tab: number) => {
      // clear the selected before changing tabs
      setSelected(undefined);
      history.push(`/curriculum/evaluations/${tabPath(availableTabs, tab)}`);
    },
    [history, availableTabs],
  );

  const isReadonly = useMemo(() => appStore.activeRole === Role.FRAMEWORK_ANALYST, [appStore.activeRole, vendor]);

  const datas = async () => {
    const { jobs } = await jobsService.listJobs({}, {}, jobFields);
    const data: Array<Record<string, string | number | boolean>> = [];

    jobs.forEach(j => {
      const testResults = j.stages.flatMap(x => x.testResults);

      testResults.forEach(t => {
        const row = {
          jobDate: j.createdAt.toISOString(),
          vendorId: j.vendorId,
          name: escape(j.name),
          evaluationId: j.evaluationId,
          jobStatus: j.status,
          jobIsExcluded: j.isExcluded,
          testDate: t.createdAt.toISOString(),
          testVendorId: t.vendorId,
          testName: escape(t.name),
          testTags: escape(t.tags.join(', ')),
          testPrevented: t.prevented,
          testDetected: t.detected,
          eventCount: t.events.length,
        };

        data.push(row);
      });
    });

    return data;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12 }}>
          <Typography variant="h2">Evaluations</Typography>
          <CsvDownload filename="All Live Fire.csv" datas={datas} />
        </div>
        {!isReadonly && vendor !== 'picus' && (
          <NewEvaluationButtons vendor={vendor} evaluation={cloned} onCloseNewModal={() => setCloned(undefined)} />
        )}
      </div>
      {!isReadonly && (
        <div className={classes.menu}>
          <Tabs
            selected={selectedTab}
            onSelect={handleTabSelect}
            labels={[...availableTabs.values()]}
            variant="fullWidth"
          />
        </div>
      )}
      <div className={classes.content}>
        {!type.includes('run') && (
          <FilterProvider>
            <RecentlyRunEvaluations />
          </FilterProvider>
        )}
        {type === 'run-evaluation' && (
          <EvaluationManager
            selected={selected}
            value={evalSearchValue}
            onChange={setEvalSearchValue}
            onClone={setCloned}
            onSelect={setSelected}
            isExams={false}
            height="calc(100% + 81px)"
          />
        )}
        {type === 'run-exam' && (
          <EvaluationManager
            selected={selected}
            value={examSearchValue}
            onChange={setExamSearchValue}
            onClone={setCloned}
            onSelect={setSelected}
            isExams={true}
            height="calc(100% + 81px)"
          />
        )}
      </div>
    </div>
  );
}
