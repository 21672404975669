import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import appStore from '../../../../../../AppStore';
import { useAbort } from '../../../../../../effects';
import useWhosOnline from '../../../../../reusables/useWhosOnline';
import {
  evaluationsService,
  examsService,
  jobsService,
  liveFireTrainingService,
  systemUsersService,
  usersService,
} from '../../../../../services';
import {
  Evaluation,
  Exam,
  FrameworkItem,
  JobActors,
  RunExamInput,
  RunJobInput,
  RunOption,
  RunOptionInput,
  StartLiveFireTrainingArgs,
} from '../../../../../services/interfaces';
import {
  CollectionMethod,
  DisplayStage,
  EvaluationNodeConfig,
  EvaluationWindowContextState,
  ScenarioTarget,
  TargetUser,
} from '../interfaces';
import EvaluationWindowContext from './EvaluationWindowContext';
import getRunner from './runners';
import Stepper from './Steps/Stepper';

const EVALUATION_FIELDS =
  '{ id name description vendorId vendor stages { tests { id type name vendorId vendor tags } } isCustom }';
const EXAM_FIELDS = '{ id name description testIds vendor tests { id type vendorId name tags } }';

export default function EvaluationWindow({ activeId, isExam = false }: EvaluationWindowProps) {
  const history = useHistory();
  const vendor = appStore.SIP;
  const runner = useMemo(() => getRunner(vendor), [vendor]);
  const [selected, setSelected] = useState<Evaluation | Exam>();
  const [isRunnable, setIsRunnable] = useState<boolean | string>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [actorOptions, setActorOptions] = useState<JobActors[]>();
  const [actorSettings, setActorSettings] = useState<EvaluationNodeConfig[]>([]);
  const [testOptions, setTestOptions] = useState<RunOption[][]>([]);
  const [testOptionInputs, setTestOptionInputs] = useState<RunOptionInput[][]>([]);
  const [collectionMethod, setCollectionMethod] = useState<CollectionMethod>('');
  const [analysts, setAnalysts] = useState<TargetUser[]>([]);
  const [selectedAnalysts, setSelectedAnalysts] = useState<string[]>([]);
  const [stoppageTime, setStoppageTime] = useState(240);
  const [scenarioTarget, setScenarioTarget] = useState<ScenarioTarget>(isExam ? 'analyst' : '');
  const [whosOnline, askNow] = useWhosOnline();
  const [dryRun, setDryRun] = useState<boolean>(false);
  const [startAfter, setStartAfter] = useState<Date>();
  const [startBefore, setStartBefore] = useState<Date>();
  const [hasTicketing, setHasTicketing] = useState(true);
  const [frameworkControls, setFrameworkControls] = useState<FrameworkItem[]>([]);

  // refresh who's online with page load
  useEffect(() => {
    askNow();
  }, []);

  /**
   * checks for a ticketing and users and sets the default
   * for collection method accordingly
   */
  useAbort(
    () =>
      Promise.all([
        systemUsersService.list('ticketing', '{ type }'),
        usersService.list(false, '{ id name email roles group }'),
      ]),
    ([sysUsers, users]) => {
      setAnalysts(users);
      setHasTicketing(!!sysUsers.length);
      setCollectionMethod(sysUsers.length ? 'ticket' : 'srp');
    },
    [],
    undefined,
    false,
  );

  // update user status
  useEffect(() => {
    setAnalysts(prev =>
      prev.map(user => {
        user.isOnline = whosOnline.some(u => u.id === user.id);
        user.isSelected = selectedAnalysts.some(id => id === user.id);
        return user;
      }),
    );
  }, [selectedAnalysts, whosOnline]);

  /** pull the entire item from the backend */
  useAbort(
    async () => {
      if (!activeId) {
        return;
      }

      if (isExam) {
        return examsService.find(activeId, EXAM_FIELDS);
      }

      return evaluationsService.find(vendor, activeId, EVALUATION_FIELDS);
    },
    item => setSelected(item),
    [vendor, isExam, activeId],
  );

  /** pull actor options for the selected evaluation */
  useAbort(
    async () => {
      if (!selected) {
        return [];
      }

      return runner.fetchActorOptions(selected);
    },
    setActorOptions,
    [selected, runner],
  );

  /** get run options for the selected evaluation */
  useAbort(
    () => {
      if (!selected) {
        return [];
      }

      return runner.fetchTestOptions(selected);
    },
    runOptionStages => {
      setTestOptionInputs(
        runOptionStages.map(runOptions =>
          runOptions
            // ignore drop downs because we can require those easily
            .filter(runOption => runOption.default !== null)
            .map(runOption => {
              // set defaults because MSV required fields are fake required 😑
              return { key: runOption.key, value: runOption.default as string };
            }),
        ),
      );
      setTestOptions(runOptionStages);
    },
    [selected, runner],
  );

  useEffect(() => {
    setActorOptions(undefined);
    setActorSettings([]);
    setTestOptions([]);
    setTestOptionInputs([]);
    setSelectedAnalysts([]);
    setStoppageTime(240);
    setScenarioTarget('');
    setStartAfter(undefined);
    setStartBefore(undefined);
    // set the active step separately to ensure
    // next button state is updated accordingly
    setTimeout(() => {
      setActiveStep(0);
    });
    setFrameworkControls([]);
  }, [activeId]);

  const label = isExam ? 'exam' : 'evaluation';

  let stages: DisplayStage[] = [];
  if (selected && 'stages' in selected) {
    stages = selected.stages.map((s, idx) => {
      return {
        name: s.name ?? `Group ${idx + 1}`,
        tests: s.tests.map(t => ({ name: t.name, vendorId: t.vendorId, type: t.type, tags: t.tags })),
      };
    });
  } else if (selected) {
    stages = selected.tests.map((t, indx) => ({
      name: `Stage ${indx + 1}`,
      tests: [{ name: t.name, vendorId: t.vendorId, type: t.type, tags: t.tags }],
    }));
  }

  const handleUpdateTestOption = (stageIndx: number, input: RunOptionInput) =>
    setTestOptionInputs(prev => {
      const clone = [...prev];
      clone[stageIndx] = [...(prev[stageIndx] ?? []).filter(o => o.key !== input.key), input];
      return clone;
    });

  const handleUpdateSelectedAnalysts = (selected: string[]) => {
    // check the ones that haven't been checked
    const checkedIds = selected.filter(c => !selectedAnalysts.includes(c));

    // unchecked the ones that have been
    const remainingIds = selectedAnalysts.filter(c => !selected.includes(c));

    setSelectedAnalysts([...checkedIds, ...remainingIds]);
  };

  const jobInput = (): Omit<RunJobInput, 'evaluationId' | 'vendor'> => ({
    frameworkControls: frameworkControls.map(x => x.id),
    isExcluded: dryRun,
    stages: [...Array(Math.max(actorSettings.length, testOptionInputs.length)).keys()].map(stageIndx => {
      const { attacker: src, target: dest } = actorSettings[stageIndx] ?? {};
      const options = testOptionInputs[stageIndx] ?? [];

      return { src, dest, options };
    }),
  });

  const runLiveTest = async () => {
    appStore.beginLoading();
    try {
      const input = jobInput();
      const response = await jobsService.runJob(
        {
          evaluationId: activeId as string,
          vendor,
          ...input,
        },
        '{ id }',
      );
      appStore.success(`Evaluation ${response.id} was successfully executed`);
      history.push(`/curriculum/evaluations/job/${response.id}`);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const startTraining = async () => {
    const input = jobInput();

    const lft: StartLiveFireTrainingArgs = {
      evaluationId: activeId as string,
      vendor,
      analysts: selectedAnalysts,
      isSelfTest: selectedAnalysts.includes(appStore.user?.id),
      source: collectionMethod,
      timeLimit: stoppageTime,
      ...input,
    };

    appStore.beginLoading();
    try {
      const response = await liveFireTrainingService.start(lft, '{ id }');
      appStore.success('Live Fire exercise(s) successfully created');
      if (response.length === 1) {
        history.push(`/curriculum/evaluations/lft/${response[0].id}`);
      } else {
        history.push('/curriculum/evaluations/live-fire-training');
      }
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const startExam = async () => {
    appStore.beginLoading();
    try {
      const input = jobInput();

      const options: RunExamInput = {
        examId: activeId as string,
        analysts: selectedAnalysts,
        isSelfTest: selectedAnalysts.includes(appStore.user?.id),
        source: collectionMethod,
        timeLimit: stoppageTime,
        ...(startAfter && { start: startAfter }),
        ...(startBefore && { end: startBefore }),
        ...input,
      };

      if (startAfter || startBefore) {
        await examsService.schedule(options);
        appStore.success('Exam successfully scheduled');
      } else {
        await examsService.run(options);
        appStore.success('Exam successfully created');
      }

      history.push('/curriculum/evaluations/exam');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const handleRunEvaluation = () => {
    if (isExam) {
      return startExam();
    }

    if (scenarioTarget === 'liveTest') {
      return runLiveTest();
    }

    return startTraining();
  };

  const contextValue: EvaluationWindowContextState = {
    activeStep,
    disableNextBtn,
    label,
    actorOptions,
    actorSettings,
    analysts,
    collectionMethod,
    dryRun,
    hasTicketing,
    isRunnable,
    scenarioTarget,
    stages,
    startAfter,
    startBefore,
    stoppageTime,
    testOptions,
    testOptionInputs,
    selected,
    frameworkControls,
    handleSetActiveStep: setActiveStep,
    handleDisableNextBtn: setDisableNextBtn,
    handleUpdateActorSettings: setActorSettings,
    handleUpdateCollectionMethod: setCollectionMethod,
    handleUpdateIsRunnable: setIsRunnable,
    handleUpdateScenarioTarget: setScenarioTarget,
    handleUpdateSelectedAnalysts,
    handleUpdateStoppageTime: setStoppageTime,
    handleUpdateTestOption,
    handleUpdateDryRun: setDryRun,
    handleUpdateStartBefore: setStartBefore,
    handleUpdateStartAfter: setStartAfter,
    handleRunEvaluation,
    setFrameworkControls,
  };

  return (
    <EvaluationWindowContext.Provider value={contextValue}>
      <Stepper runner={runner} />
    </EvaluationWindowContext.Provider>
  );
}

interface EvaluationWindowProps {
  activeId?: string;
  isExam?: boolean;
}
