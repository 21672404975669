import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import { LastRun } from '../../../../../services/interfaces';

type LastRunStatus =
  | 'running'
  | 'queued'
  | 'waiting'
  | 'ended'
  | 'completed'
  | 'errored'
  | 'cancelled'
  | 'discarded'
  | 'passed'
  | 'released';

const useStyles = makeStyles({
  running: {
    color: '#117fba',
  },
  queued: {
    color: 'rgba(71, 146, 247, 0.28)',
  },
  waiting: {
    color: 'rgba(71, 146, 247, 0.28)',
  },
  ended: {
    color: '#5d6878',
  },
  completed: {
    color: '#21bee3',
  },
  errored: {
    color: '#f85c5c',
  },
  cancelled: {
    color: '#a7adb5',
  },
  discarded: {
    color: '#a7adb5',
  },
  passed: {
    color: '#0a0c10',
  },
  released: {
    color: 'rgb(0, 255, 177)',
  },
});

export default function LastRunText({ lastRun }: { lastRun?: LastRun }) {
  const classes = useStyles();

  const validStatuses: LastRunStatus[] = [
    'running',
    'queued',
    'waiting',
    'ended',
    'completed',
    'errored',
    'cancelled',
    'discarded',
    'passed',
    'released',
  ];

  let statusClass = '';
  if (lastRun?.status && validStatuses.includes(lastRun.status as LastRunStatus)) {
    statusClass = classes[lastRun.status as LastRunStatus];
  }

  return (
    <>
      <span className={statusClass}>{capitalize(lastRun?.status ?? '')}</span>{' '}
      {lastRun?.createdAt && format(lastRun.createdAt, 'yyyy-MM-dd, HH:mm')}
    </>
  );
}
