import { FormLabel, RadioGroup, TextField, FormControlLabel, Radio, FormControl, Box, Typography } from '@mui/material';
import Modal from '../../../../../reusables/Modal';
import { makeStyles } from '@mui/styles';
import { useState, FormEvent, ChangeEvent } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';
import { AssessorInput, AssessorScore } from '../../../../../services/interfaces';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    minWidth: 500,
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  dropzone: {
    position: 'relative',
    zIndex: 2,
  },
  dropzoneText: {
    width: '100%',
    zIndex: 1,
    position: 'absolute',
    left: 0,
    bottom: '1.5rem',
    '& p': {
      ...theme.typography.textStyle2,
      color: theme.palette.grey1.main,
      textAlign: 'center',
    },
  },
}));

interface InputModalProps {
  open?: boolean;
  onAdd: (input: AssessorInput) => Promise<void>;
  onClose: () => void;
  tacticId?: string;
}

export default function InputModal({ open = false, onAdd, onClose, tacticId = '' }: InputModalProps) {
  const classes = useStyles();
  const [score, setScore] = useState<AssessorScore | ''>('');
  const [numScore, setNumScore] = useState<number | string>(0);
  const [comments, setComments] = useState<string>('');
  const [files, setFiles] = useState<File[]>();

  const handleAddClick = async (event: FormEvent) => {
    event.preventDefault();
    onAdd({
      tacticId: tacticId as string,
      comments,
      score: score as AssessorScore,
      value: +numScore,
      files,
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNumScore(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (numScore === '') {
      setNumScore(0);
    } else if (+numScore > 100) {
      setNumScore(100);
    } else if (+numScore < 0) {
      setNumScore(0);
    } else if (isNaN(+numScore)) {
      setNumScore(0);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleAddClick}
      actions={[
        { text: 'Add', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container} data-testid="assessor-input-modal">
        <FormControl>
          <FormLabel>Pass/Fail</FormLabel>
          <div style={{ display: 'flex' }}>
            <RadioGroup
              name="assessor-input-pass-fail"
              row
              value={score}
              onChange={e => setScore(e.target.value as AssessorScore)}
            >
              <FormControlLabel value="pass" control={<Radio required />} label="Pass" />
              <FormControlLabel value="fail" control={<Radio required />} label="Fail" />
              <FormControlLabel value="n/a" control={<Radio required />} label="N/A" />
              <FormControlLabel value="value" control={<Radio required />} label="Value" />
            </RadioGroup>
            <TextField
              value={numScore}
              type="number"
              onChange={handleChange}
              style={{ width: 100 }}
              onBlur={handleBlur}
              inputProps={{
                step: 1, // Only allows integer steps
                min: 0, // Minimum value of 0
                max: 100, // Maximum value of 100
              }}
              disabled={score !== 'value'}
              variant="outlined"
            />
          </div>
        </FormControl>
        <FormControl>
          <TextField
            placeholder="Comments"
            minRows={5}
            maxRows={5}
            multiline
            variant="outlined"
            value={comments}
            onChange={e => setComments(e.target.value)}
            fullWidth
            required
          />
        </FormControl>
        <Box className={classes.dropzone}>
          <DropzoneArea
            fileObjects={files}
            onChange={f => setFiles(f)}
            acceptedFiles={[
              'image/png',
              'image/jpeg',
              'text/csv',
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
          />
          <Box className={classes.dropzoneText}>
            <Typography>Max file size: 3MB</Typography>
            <Typography>Supported file types: png, jpeg, csv, pdf, doc , docx, xls, xlsx</Typography>
          </Box>
        </Box>
      </div>
    </Modal>
  );
}
