import Chart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { parseNaN, round } from '@sightgain/core/calculations';
import { NumberBadge } from '../../../reusables/Badges';

interface TacticDetailTileProps {
  name: string;
  score: number;
  color: string;
  trend: (number | null)[];
}

const CHARTOPTIONS = {
  chart: {
    animations: {
      enabled: false,
    },
    dynamicAnimation: {
      enabled: false,
    },
    foreColor: '#ffffff',
    type: 'area',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#2196F3'],
  grid: {
    show: false,
  },
  legend: {
    show: false,
  },
  stroke: {
    curve: 'smooth',
    width: 1,
  },
  xaxis: {
    labels: {
      show: false,
    },
    show: false,
    min: 0,
    max: 24,
    axisTicks: {
      show: false,
    },
    title: {
      text: 'Risk Score - Weekly Average',
      offsetY: -4,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    show: false,
    min: 0,
    max: 100,
  },
  tooltip: {
    enabled: false,
  },
};

const validScore = (score: number | null) => {
  if (score === null || score < 0) {
    return 'N/A';
  }
  return `${parseNaN(round(score, 0))}`;
};

export default function TacticDetailTile({ name, score, color, trend }: TacticDetailTileProps) {
  const series = [
    {
      name: 'CAPMET',
      data: trend.map(s => s ?? 0),
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 295,
        width: 368,
        padding: '40px 24px 40px 24px',
        background: color,
        borderRadius: 3,
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h3" style={{ textTransform: 'capitalize', fontWeight: '600' }}>
          {name}
        </Typography>
        <div>
          <NumberBadge tag lg color={color}>
            {validScore(score)}
          </NumberBadge>
        </div>
      </div>
      <Chart options={CHARTOPTIONS} series={series} type="area" height="100" />
    </div>
  );
}
