import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { MouseEvent } from 'react';

const useStyles = makeStyles({
  title: {
    fontSize: '18px',
  },
  sub: {
    fontSize: '16px',
  },
});

export default function ModalHeading({ title, error = '', sub = false, status, onChange = () => {} }: HeadingProps) {
  const classes = useStyles();

  const handleStatusOnChange = (e: MouseEvent<HTMLElement>, value: any) => {
    if (!value) {
      return;
    }

    onChange({ target: { value } });
  };

  return (
    <Grid container item alignItems="center">
      <Grid item xs={6} md={6} lg={6}>
        <Typography component={sub ? 'h3' : 'h2'} className={clsx(classes.title, { 'classes.sub': sub })}>
          {title}
        </Typography>
      </Grid>
      {status && (
        <Grid container item xs={6} md={6} lg={6} justifyContent="flex-end">
          <ToggleButtonGroup exclusive value={status} onChange={handleStatusOnChange}>
            <ToggleButton value="Active" aria-label="list">
              Active
            </ToggleButton>
            <ToggleButton value="Disabled" aria-label="list">
              Disabled
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      <Grid item>{!!error && <Typography color="error">{error}</Typography>}</Grid>
    </Grid>
  );
}
interface HeadingProps {
  title: string;
  error?: string;
  status?: string;
  onChange?: (e: { target: { value: 'Active' | 'Disabled' } }) => void;
  sub?: boolean;
}
