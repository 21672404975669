import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DateCalendar } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatDateISO } from '@sightgain/core/dates';
import Modal from '../../../../reusables/Modal';
import { useFrameworkContext } from '../FrameworkContext';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    gap: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  vs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface CompareModalProps {
  open: boolean;
  onCompare: (day1: string, day2: string) => Promise<void>;
  day1: string;
  day2: string;
  minDate: Date;
}

export default function CompareModal({ open, onCompare, day1, day2, minDate }: CompareModalProps) {
  const classes = useStyles();

  const [newDay1, setNewDay1] = useState(day1 === '' ? format(new Date(), 'yyyy-MM-dd') : day1);
  const [newDay2, setNewDay2] = useState(day2 === '' ? format(new Date(), 'yyyy-MM-dd') : day2);

  const { setModalCompareProperties, setScoredFrameworkDays, scoredFrameworkDays } = useFrameworkContext();

  const beforeClose = () => {
    setModalCompareProperties({ open: false, day1, day2 });
  };

  const handleCompareClick = async () => {
    await onCompare(newDay1, newDay2);
    setModalCompareProperties({ open: false, day1: newDay1, day2: newDay2 });
    setScoredFrameworkDays([...scoredFrameworkDays, { day1: newDay1, day2: newDay2 }]);
  };

  return (
    <Modal
      open={open}
      onClose={() => beforeClose()}
      onSubmit={() => {}}
      title="Compare Framework Scoring by Date"
      actions={[
        { text: 'Compare', color: 'primary', onClick: () => handleCompareClick() },
        { text: 'Cancel', onClick: () => beforeClose() },
      ]}
    >
      <div className={classes.container}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            value={parseISO(newDay1)}
            onChange={d => setNewDay1(formatDateISO(d as Date))}
            defaultValue={parseISO(newDay1)}
            disableFuture
            minDate={minDate}
          />
        </LocalizationProvider>
        <div className={classes.vs}>
          <Typography variant="h5">VS</Typography>
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            value={parseISO(newDay2)}
            onChange={d => setNewDay2(formatDateISO(d as Date))}
            defaultValue={parseISO(newDay2)}
            disableFuture
            minDate={minDate}
          />
        </LocalizationProvider>
      </div>
    </Modal>
  );
}
