import { gql } from '@apollo/client';
import ServiceBase from './ServiceBase';
import { SystemUser } from './interfaces';

export class SiemService extends ServiceBase {
  async testConnection(configuration: SystemUser) {
    const query = gql`
      mutation SiemConnection($input: SystemUserDataInput!) {
        siemConnection(input: $input)
      }
    `;

    const { siemConnection } = await this.graphql(query, { input: configuration });
    return siemConnection;
  }
}

const siemService = new SiemService();
export default siemService;
