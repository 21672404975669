import { Button, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface ControlProps {
  filter: string;
  onFilterChange: (e: SelectChangeEvent) => void;
  onImport: () => void;
}

export default function Controls({ filter, onFilterChange, onImport }: ControlProps) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 0 }}>
      <div>
        <Select value={filter} onChange={onFilterChange} style={{ width: 200 }}>
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="system">System Provided</MenuItem>
          <MenuItem value="cloned">Cloned</MenuItem>
        </Select>
      </div>
      <Button variant="contained" onClick={onImport}>
        Import Framework
      </Button>
    </div>
  );
}
