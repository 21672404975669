import { FormEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { pick } from '@sightgain/core';
import { capitalize } from '@sightgain/core/strings';
import Modal from '../../../reusables/Modal';
import TextField from '../../../reusables/TextField';
import { Company } from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  row: {
    marginTop: 30,
    width: 619,
  },
}));

const INDUSTRIES = [
  'Consumer Discretionary',
  'Consumer Staples',
  'Defense',
  'Energy',
  'Financials',
  'Health Care',
  'Industrial',
  'Information Technology',
  'Materials',
  'Real State',
  'Telecommunications',
  'Utilities',
];

const INVESTMENTS = ['antivirus/edr', 'firewall', 'ids/ips', 'dlp', 'email', 'analyst', 'proxy', 'waf'];
type ErrorKeys = 'name' | 'industry' | 'employees' | 'investment';

export default function UpdateCompanyModal({ onClose, open = false, companyInfo: info }: UpdateCompanyModalProps) {
  const classes = useStyles();

  const [companyInfo, setCompanyInfo] = useState<Company>(info);
  const [errors, setErrors] = useState<Record<ErrorKeys, string>>({
    name: '',
    industry: '',
    employees: '',
    investment: '',
  });

  /**
   * Save the data in the modal
   * @param event Form event
   */
  const handleModalSave = (event: FormEvent) => {
    event.preventDefault();

    // ensure that only the appropriate fields are being sent back
    const investments = companyInfo.investments.map(({ name, value }) => ({ name, value }));
    onClose({
      ...(pick(companyInfo, [
        'name',
        'industry',
        'location',
        'employees',
        'revenue',
        'ddosLoss',
        'ipValue',
        'piiRecords',
      ]) as Company),
      investments,
    });
  };

  /**
   * Checks that required fields have a value
   * @param field Field label
   * @param value Value form form
   */
  const checkRequired = (field: string, value: string) => {
    if (!value || value === '0') {
      return `${field} is a required field`;
    }

    return '';
  };

  /**
   * Updates the local veariable from the form
   */
  const handleOnChange = (propName: string, label?: string) => {
    return ({ target: { value } }: { target: { value: string } }) => {
      const errs: Record<string, string> = {};
      const updates: Record<string, number | string> = {};

      // label having a value means it is required
      if (label) {
        errs[propName] = checkRequired(label, value);
      }

      // name, industry, and location are string values
      if (['name', 'industry', 'location'].indexOf(propName) >= 0) {
        updates[propName] = value;
      } else {
        // everything else is a number
        updates[propName] = +value;
      }

      // ipValue is 70% of revenue
      if (propName === 'revenue' && !companyInfo.ipValue) {
        updates.ipValue = +updates.revenue * 0.7;
      }

      setErrors({ ...errors, ...errs });
      setCompanyInfo({ ...companyInfo, ...updates });
    };
  };

  const handleInvestmentOnChange = (name: string) => (e: { target: { value: string } }) => {
    const value = +e.target.value;
    // looks weird but works because NaN is NOT > 0
    if (!(value >= 0)) {
      setErrors({ ...errors, [name]: 'invalid value' });
      return;
    }

    setCompanyInfo(prev => ({
      ...prev,
      investments: [...prev.investments.filter(i => i.name !== name), { name, value }],
    }));
  };

  const money: [string, keyof Company][] = [
    ['Revenue', 'revenue'],
    ['IP Value', 'ipValue'],
    ['PII Records', 'piiRecords'],
    ['Service Disruption Cost (per day)', 'ddosLoss'],
  ];

  return (
    <Modal open={open} title="Update Company Information" onClose={() => onClose()} onSubmit={handleModalSave}>
      <div className={classes.row}>
        <TextField
          error={errors.name}
          label="Company Name"
          value={companyInfo.name}
          required
          onChange={handleOnChange('name', 'Company Name')}
        />
      </div>
      <div className={classes.row}>
        <TextField
          error={errors.industry}
          label="Industry"
          type="select"
          value={companyInfo.industry}
          onChange={handleOnChange('industry', 'Industry')}
        >
          {INDUSTRIES.map(v => (
            <MenuItem key={`industry-${v}`} value={v}>
              {v}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.row}>
        <TextField label="Location" value={companyInfo.location} onChange={handleOnChange('location')} />
      </div>
      <div className={classes.row}>
        <TextField
          error={errors.employees}
          label="Company Size (Number of employees)"
          value={`${companyInfo.employees}`}
          required
          onChange={handleOnChange('employees', 'Company Size')}
        />
      </div>
      {money.map(([label, prop]) => (
        <div key={`ucm-wrapper-${prop}`} className={classes.row}>
          <TextField
            key={`ucm-${prop}`}
            label={label}
            error={errors[prop as ErrorKeys]}
            value={`${companyInfo[prop]}`}
            required={prop !== 'ddosLoss'}
            onChange={handleOnChange(prop, prop !== 'ddosLoss' ? label : undefined)}
          />
        </div>
      ))}
      {INVESTMENTS.map(investment => (
        <div key={`ucm-wrapper-${investment}`} className={classes.row}>
          <TextField
            key={`ucm-${investment}`}
            label={capitalize(investment)}
            error={errors[investment as ErrorKeys]}
            value={`${companyInfo.investments.find(i => i.name === investment)?.value ?? 0}`}
            onChange={handleInvestmentOnChange(investment)}
          />
        </div>
      ))}
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <Button variant="contained" color="primary" onClick={handleModalSave} data-testid="save-new-item">
          Save &nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Button>
        <Button variant="outlined" style={{ marginLeft: 24 }} onClick={() => onClose()}>
          Cancel &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Button>
      </div>
    </Modal>
  );
}

interface UpdateCompanyModalProps {
  open?: boolean;
  companyInfo: Company;
  onClose: (c?: Company) => void;
}
