import chinaFlag from '../../images/countries/china.svg';
import financialFlag from '../../images/countries/financial.svg';
import gazaFlag from '../../images/countries/gaza.svg';
import indiaFlag from '../../images/countries/india.svg';
import iranFlag from '../../images/countries/iran.svg';
import lebanonFlag from '../../images/countries/lebanon.svg';
import nigeriaFlag from '../../images/countries/nigeria.svg';
import northkoreaFlag from '../../images/countries/north-korea.svg';
import pakistanFlag from '../../images/countries/pakistan.svg';
import romaniaFlag from '../../images/countries/romania.svg';
import russiaFlag from '../../images/countries/russia.svg';
import southkoreaFlag from '../../images/countries/south-korea.svg';
import turkeyFlag from '../../images/countries/turkey.svg';
import uaeFlag from '../../images/countries/united-arab-emirates.svg';
import unitedstatesFlag from '../../images/countries/united-states.svg';
import unknownFlag from '../../images/countries/unknown.svg';
import vietnamFlag from '../../images/countries/vietnam.svg';

export default function resolveImage(img: string) {
  return (
    {
      china: chinaFlag,
      financial: financialFlag,
      gaza: gazaFlag,
      india: indiaFlag,
      lebanon: lebanonFlag,
      nigeria: nigeriaFlag,
      pakistan: pakistanFlag,
      romania: romaniaFlag,
      'south korea': southkoreaFlag,
      turkey: turkeyFlag,
      uae: uaeFlag,
      unknown: unknownFlag,
      iran: iranFlag,
      'north korea': northkoreaFlag,
      russia: russiaFlag,
      'united states': unitedstatesFlag,
      vietnam: vietnamFlag,
    }[img] || ''
  );
}
