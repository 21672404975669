import { ComponentType, Key } from 'react';

type ComponentRegistry<T = object> = {
  [key: string]: ComponentType<T>;
};

const components: ComponentRegistry = {};
export function registerComponent<T>(name: string, Comp: ComponentType<T>) {
  components[name] = Comp as unknown as ComponentType;
}

export default function renderComponent<T>(key: Key, props: T, comp?: string) {
  const Comp = comp && Object.keys(comp).length ? components[comp] : null;
  if (!Comp) {
    return null;
  }
  return <Comp key={key} {...props} />;
}
