import { gql } from '@apollo/client';
import { Product } from './interfaces';
import ServiceBase from './ServiceBase';

const typeProduct = `
{
  id
  vendorId
  vendor
  name
  type
  description
  version
}
`;

export class ProductsService extends ServiceBase {
  /**
   * @param vendorId
   * @param fields
   */
  async find(vendorId: string, vendor: string, fields = typeProduct): Promise<Product> {
    const query = gql`
      query GetProduct($vendorId: String!) {
        product(vendorId: $vendorId) ${fields}
      }
    `;

    const { product } = await this.graphql(query, { vendorId, vendor });
    return product;
  }

  /**
   * @param fields
   */
  async list(fields = typeProduct): Promise<Product[]> {
    const query = gql`
      query Products {
        products ${fields}
      }
    `;

    const { products } = await this.graphql(query);
    return products;
  }
}

const productsService = new ProductsService();
export default productsService;
