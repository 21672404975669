import { useParams } from 'react-router';
import CurriculumService from '../../services/CurriculumService';
import { useAbort } from '../../../effects';
import { Redirect } from 'react-router-dom';
import { useState } from 'react';
import { Student } from '../../services/interfaces';

export default function AnalystsEmail() {
  const { email } = useParams<{ email: string }>();
  const [student, setStudent] = useState<Student | null>(null);

  useAbort(
    () => CurriculumService.student(email),
    result => {
      setStudent(result);
    },
  );

  if (!student) {
    return <></>;
  }

  return <Redirect to={`/curriculum/analysts/${student.id}`} />;
}
