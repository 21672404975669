import { capitalize } from '@sightgain/core/strings';
import CsvDownload from '../../../reusables/CsvDownload';
import { HeatmapChartMetric } from '../../../reusables/scl';
import { RoiInvestmentAction } from '../../../services/interfaces';

export default function TechnologyStagePrevention({
  actioned: riskReduction,
  thresholds,
}: TechnologyStagePreventionProps) {
  const { labels, ratios } = riskReduction;
  const series = ratios
    .filter(({ name }) => name !== 'analyst')
    .map(({ name, data }) => ({ name: capitalize(name), data }));

  const datas = () => {
    return series.map(s => ({
      name: s.name,
      ...labels.reduce((acc, l, idx) => {
        acc[l] = `${s.data[idx].value}/${s.data[idx].total}` || '';
        return acc;
      }, {} as Record<string, string>),
    }));
  };

  return (
    <div>
      <HeatmapChartMetric
        title={
          <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
            Technology Prevention by Stage
            <CsvDownload filename="TechnologyPreventionByStage.csv" datas={datas} />
          </div>
        }
        labels={labels.map(l => capitalize(l))}
        series={series}
        from={0}
        to={1}
        thresholds={thresholds.map(n => n / 100)}
        variant="thresholds"
        reverse
      />
    </div>
  );
}

type TechnologyStagePreventionProps = { actioned: RoiInvestmentAction; thresholds: [number, number] };
