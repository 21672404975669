export enum SIP {
  AttackIQ = 'attackiq',
  MSV = 'msv',
  Picus = 'picus',
  SET = 'testing',
  None = 'unset',
}

export interface ErrorResponse {
  message: string;
  stack?: string;
  __typename: 'ErrorResponse';
}

export interface Log {
  createdAt: Date;
  id: string;
  message: string;
  level: string;
  meta?: string;
}

export interface LogResult {
  count: number;
  rows: Log[];
}

export interface ThresholdColors {
  green: string;
  yellow: string;
  orange: string;
  red: string;
  disabled: string;
  none: string;
}

export interface PaginationInput {
  offset?: number;
  limit?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
}

export interface FileInfo {
  id: string;
  createdAt: Date;
  author: User;
  bucket: string;
  fileName: string;
  mimeType: string;
  encoding: string;
  contents: string;
}

export enum AssessorScore {
  PASS = 'pass',
  FAIL = 'fail',
  NA = 'n/a',
  VALUE = 'value',
}

export interface AssessorImport {
  source: string;
  file: File;
}

export interface AssessorInput {
  comments: string;
  score: AssessorScore;
  value?: number;
  tacticId: string;
  files?: File[];
}

export interface Assessor {
  id: string;
  createdAt: Date;
  comments: string;
  assessor: User;
  score: AssessorScore;
  value: number;
  tacticId: string;
  files?: FileInfo[];
}

export interface FrameworkItem {
  id: string;
  description: string;
  identifier: string;
  maturityLevel?: string;
  name: string;
  sub: FrameworkItem[];
  tests?: Test[];
  testIds: string[];
}

export interface FrameworkGroup {
  name: string;
  items: FrameworkItem[];
}

export interface Framework {
  _id: string;
  id: string;
  active: boolean;
  hidden: boolean;
  groups: FrameworkGroup[];
  items: FrameworkItem[];
  lastUpdated: Date;
  name: string;
  title: string;
  type: string;
  version: string;
  isSystem: boolean;
  updatedAt: Date;
  createdAt: Date;
}

export type Scored<T, S = unknown> = T & {
  scores: S;
};

export type FrameworkSet<T extends Framework = Framework> = {
  id: string;
  isSystem: boolean;
  name: string;
  title: string;
  updatedAt: Date;
  createdAt: Date;
  versions: T extends Scored<Framework> ? Scored<Framework>[] : Framework[];
};

export interface ImportFrameworkInput {
  files: File[];
}

export interface SaveFrameworkInput {
  framework: FrameworkSource;
}

export interface DailyJobMetric {
  preventedCount: number;
  detectedCount: number;
  alertedCount: number;
  missedCount: number;
  count: number;
  products: DailyJobMetricProduct[];
  date: Date;
}

export interface DailyJobMetricProduct {
  type: string;
  preventedCount: number;
  detectedCount: number;
  alertedCount: number;
}

export interface DailyExerciseMetric {
  automatedResponseCount: number;
  manualResponseCount: number;
  noResponseCount: number;
  missedCount: number;
  manualResponseTimeTotal: number;
  count: number;
  date: Date;
}

export interface ResponseResult {
  ops: string;
  type: string;
  alerts: number;
  alertsFPR: number;
  events: number;
  mttr: number;
}

export interface Snr {
  signal: number;
  noise: number;
  ratio: number;
}

export interface SnrResult {
  snr: Snr;
  tests: number;
}

export interface SocResponse {
  dailyJobMetrics: DailyJobMetric[];
  dailyExerciseMetrics: DailyExerciseMetric[];
}

export interface RiskScenarioTactic {
  id: string;
  name: string;
  identifier: string;
  technologies: string[];
}

export interface RiskScenarioStage {
  id: string;
  name: string;
  items: RiskScenarioTactic[];
}

export interface RiskScenario {
  id?: string;
  type: string;
  name: string;
  groups: RiskScenarioStage[];
}

export interface Investment {
  name: string;
  value: number;
}

export interface Company {
  name: string;
  employees: number;
  industry: string;
  location: string;
  revenue: number;
  ipValue: number;
  ddosLoss: number;
  piiRecords: number;
  investments: Investment[];
}

export interface ThreatGroupTrendInput {
  groupId: string;
  identifiers: string[];
}

export interface CreateThreatIntelInput {
  groupId: string;
  identifier: string;
  score: number;
}

export interface ThreatIntel {
  id: string;
  frameworkItem: FrameworkItem;
  createdAt: Date;
  score: number;
}

export interface ThreatGroup {
  id: string;
  createdAt: Date;
  name: string;
  scores: ThreatIntel[];
}

export interface ThreatGroupTrend {
  from: Date;
  to: Date;
  groupId: string;
  group: string;
  scores: ThreatIntel[];
}

export interface ProductBakeOffResult {
  vendorJobId: string;
  vendorTestId: string;
  testName: string;
  productName: string;
  productType: string;
  prevented: boolean;
  detected: boolean;
  createdAt: Date;
}

export interface BakeOffInput {
  startDate: Date;
  endDate: Date;
  evaluationId: string;
  products: Array<{
    name: string;
    type: string;
  }>;
}

export interface RunOptionValue {
  value: string;
  label?: string;
}

export interface RunOption {
  key: string;
  label: string;
  options?: RunOptionValue[];
  default?: string;
  isRequired?: boolean;
}

export interface RunOptionInput {
  key: string;
  value: string;
}

export interface RunJobStageInput {
  src?: string;
  dest?: string;
  options?: RunOptionInput[];
}

export interface RunJobInput {
  vendor: string;
  evaluationId: string;
  stages: RunJobStageInput[];
  isExcluded: boolean;
  frameworkControls: string[];
}

export interface Actor {
  id: string;
  ip: string;
  port?: number;
}

export interface JobActor {
  id: string;
  name: string;
  os?: string;
  ip?: string;
}

export interface JobActors {
  actors: JobActor[];
  sourceActorIds: string[];
  destinationActorIds: string[];
}

export interface ProductResult {
  id: string;
  vendorId: string;
  name: string;
  type?: string;
  prevented: boolean;
  detected: boolean;
}

export interface HostEvent {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  eventId: string;
  recordId: string;
  name: string;
  ip: string;
  provider: string;
  channel: string;
  raw: string;
}

export interface IntegrationEvent {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  eventId: string;
  srcIp?: string;
  srcPort?: number;
  destIp?: string;
  destPort?: number;
  raw: string;
}

export interface TestResultsSince {
  sinceDate: Date;
  testIds?: string[];
}

export interface TestResultFilter {
  status?: string;
  useInternalIds?: boolean;
}

export interface TestResult {
  id: string;
  vendorId: string;
  vendor: string;
  name: string;
  description?: string;
  tags: string[];
  testId: string;
  status: string;
  createdAt: Date;
  started?: Date;
  ended?: Date;
  alerted: boolean;
  detected: boolean;
  prevented: boolean;
  products: ProductResult[];
  stage: JobStage;
  hostEvents: HostEvent[];
  integrationEvents: IntegrationEvent[];
  /** @deprecated use createdAt instead */
  created: Date;
  /** @deprecated use test.evaluation.vendorId instead */
  evaluationId?: string;
  /** @deprecated use stage.job.vendorId instead */
  jobId: string;
  srcZoneId: number;
  srcZoneName: string;
  dstZoneId: number;
  dstZoneName: string;
  events: Array<HostEvent | IntegrationEvent>;
}

export interface TestResultTrendScore {
  testId: string;
  vendor: string;
  score: number;
}

export interface TestResultTrend {
  from: Date;
  to: Date;
  scores: TestResultTrendScore[];
}

export interface JobStage {
  id: string;
  vendor: string;
  vendorId: string;
  orderNo: number;
  name: string;
  /** @deprecated historical data will be separated from current data */
  stage: Stage;
  job: Job;
  testResults: TestResult[];
}

export interface JobsFilter {
  type?: string;
  status?: string;
  name?: string;
  nameMatch?: string;
  evaluationId?: string;
  evaluationIdMatch?: string;
  isExcluded?: boolean;
  analystEmail?: string;
  analystEmailMatch?: string;
  startDate?: Date;
  startDateMatch?: string;
}

export interface Job {
  id: string;
  isExcluded: boolean;
  vendor: string;
  vendorId: string;
  evaluationId: string;
  name: string;
  description?: string;
  status: string;
  stages: JobStage[];
  createdAt: Date;
  updatedAt: Date;
  trainings: LiveFireTraining[];
  frameworkControls: FrameworkItem[];
}

export interface JobsResult {
  jobs: Job[];
  count: number;
}

export interface JobSummary {
  name: string;
  description?: string;
  evaluationId: string;
  runCount: number;
}

export interface CreateEvaluationInput {
  name: string;
  vendor: string;
  description: string;
  testIds: string[];
}

export interface Stage {
  id: string;
  name: string;
  description?: string;
  orderNo: number;
  vendor: string;
  vendorId: string;
  tests: Test[];
}

export interface LastRun {
  testResults: TestResult[];
  status: string;
  createdAt: Date;
}

export interface Evaluation {
  id: string;
  name: string;
  description: string;
  isCustom: boolean;
  tags: string[];
  vendor: string;
  vendorId: string;
  stages: Stage[];
  runCount: number;
  testResults: TestResult[];
  lastRun?: LastRun;
  __typename: 'Evaluation';
}

export interface AnalystInput {
  id: string;
  createdAt: Date;
  description: string;
  event: string;
  matchedEvents: Array<HostEvent | IntegrationEvent>;
}

export interface AddAnalystInputArgs {
  id: string;
  description: string;
  event: string;
}

export interface InstructorFeedbackArgs {
  id: string;
  feedback: string;
  score: number;
}

export interface LiveFireTrainingEventActor {
  ip: string;
  port: number;
}

export type LiveFireTrainingStatus =
  | 'waiting'
  | 'ran'
  | 'running'
  | 'stoppage time'
  | 'ended'
  | 'completed'
  | 'errored'
  | 'discarded'
  | 'sleeping'
  | 'passed'
  | 'cancelled'
  | 'queued'
  | 'released';

export type LiveFireTrainingSource = 'srp' | 'ticket';

export interface LiveFireTraining {
  id: string;
  started: Date;
  analyst: User;
  source: LiveFireTrainingSource;
  completed: Date;
  createdAt: Date;
  ended: Date;
  job: Job;
  parent?: LiveFireTraining;
  feedback: string;
  instructorScore: number;
  isReleased: boolean;
  isSelfTest: boolean;
  isExam: boolean;
  status: LiveFireTrainingStatus;
  timeLimit: number;
  updatedAt: Date;
  analystInput: AnalystInput[];
  timePassed: number;
  eventScore: number;
  precisionScore: number;
  responseScore: number;
  score: number;
}

export interface LiveFireTrainingListArgs {
  statuses?: string[];
  isRunning?: boolean;
  isReleased?: boolean;
  hideRetakes?: boolean;
  analyst?: string;
}

export interface StartLiveFireTrainingArgs {
  vendor: string;
  evaluationId: string;
  source: string;
  analysts: string[];
  isSelfTest: boolean;
  isExcluded: boolean;
  parentId?: string;
  timeLimit: number;
  stages: RunJobStageInput[];
}

export interface Test {
  id: string;
  vendor: string;
  vendorId: string;
  name: string;
  description: string;
  type: string;
  tags: string[];
  createdAt: Date;
}

export interface TestWithRunInfo extends Test {
  lastRun: {
    prevented: boolean;
    detected: boolean;
    createdAt: Date;
  };
}

export enum TrainingGroupType {
  curriculum = 'curriculum',
  course = 'course',
  class = 'class',
}

export enum TrainingGroupStatus {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed',
}

export enum TrainingGroupLevel {
  Tier1 = 'tier-1',
  Tier2 = 'tier-2',
  Tier3 = 'tier-3',
  LiveFire = 'livefire',
  LiveFireComment = 'livefire-comment',
}

export interface TrainingGroupPreview {
  id: string;
  title: string;
  progress?: number;
  status: TrainingGroupStatus;
  type: string;
  description: string;
  difficulty?: TrainingGroupLevel;
  durationMinutes: number;
  durationDays: number;
  curriculums: TrainingGroupDetails[];
}

export interface TrainingGroupMetadataTableRow {
  entryId: string;
  link?: string;
  description: string;
}

export interface TrainingGroupMetadata {
  title: string;
  table?: TrainingGroupMetadataTableRow[];
}

export interface TrainingGroupDetails {
  id: string;
  title: string;
  type: TrainingGroupType;
  description: string;
  descriptionShort: string;
  metadata: TrainingGroupMetadata[];
  difficulty?: TrainingGroupLevel;
  progress?: number;
  status: TrainingGroupStatus;
  durationMinutes: number;
  durationDays: number;
  expirationDays?: number;
  courses: TrainingGroupPreview[];
  classes: TrainingGroupPreview[];
}

export interface RunLiveFire {
  id: string;
  options: RunOptionInput[][];
}

export interface TrainingGroupLivefireContent {
  id: string;
  title: string;
  description?: string;
  livefireId: string;
  testIds: string[][];
}

export interface Content {
  component: string;
  props: string;
}

export interface Lesson {
  title: string;
  content: Content[];
}

export interface Question {
  title: string;
  description: string;
  options: string[];
}

export interface Answer {
  title: string;
  description: string;
  answer: string;
  correctAnswer: string;
  correct: boolean;
}

export interface GuidedTrainingDetails {
  completed: boolean;
  answers?: Answer[];
  quiz: Question[];
  lessons: Lesson[];
}

export interface GuidedTrainingPreview {
  title: string;
  completed: boolean;
  lessonIds: string[];
  quizId: string;
}

export interface LivefirePreview {
  id: string;
  completed: boolean;
  name?: string;
  description?: string;
  status?: string;
}

export interface TrainingGroupContents {
  title: string;
  description: string;
  progressPercent: number;
  guidedTraining: GuidedTrainingPreview[];
  livefire: LivefirePreview[];
}

export interface CalendarEvent {
  id: string;
  owner: string;
  attendee: string;
  day: string;
  time?: string;
  description?: string;
  trainingGroup: TrainingGroupPreview;
  status: string;
}

export enum QuizResult {
  pass = 'pass',
  fail = 'fail',
}

export interface QuestionInput {
  title: string;
  options: string[];
}

export interface SubmitQuizInput {
  student: string;
  quizId: string;
  lessonIds: string[];
  answers: string;
  instance: QuestionInput[];
}

export interface CreateCalendarEventInput {
  owner: string;
  attendee: string;
  day: string;
  time?: string;
  description?: string;
  trainingGroupId: string;
  status: string;
}

export interface LogActivityInput {
  student: string;
  contentId: string;
  groupIds: string[];
}

export interface Achievement {
  name: string;
  tags: string[];
  requirements: string[];
}

export interface AchievementProgress {
  student: string;
  achievement: Achievement;
  startDate?: Date;
  completionDate?: Date;
  completedPercent: number;
  guidance: string[];
}

export interface StudentActivity {
  completed: TrainingGroupPreview[];
  incomplete: TrainingGroupPreview[];
}

export interface StudentActivityContent {
  completed: TrainingContent[];
  incomplete: TrainingContent[];
}

export interface livefirePreview {
  id: string;
  evaluationId?: string;
  name: string;
  description: string;
}

export interface TrainingContent {
  id: string;
  type: string;
  livefireEvaluation?: livefirePreview;
}

export interface StudentActivityTime {
  endTimestamp: Date;
  durationSeconds: number;
}

export interface ActivityTimeInput {
  student: string;
  start: Date;
  end: Date;
}

export interface StudentActivityGroup {
  title: string;
  difficulty?: string;
  itemCount: number;
  retakeCount: number;
  nrOfAchievementsEarned: number;
  totalTimeSpentMinutes: number;
}

export interface StudentDashboard {
  totalLifetimeTrainingDurationMinutes: number;
  trainingMinutesThisWeek: number;
  trainingMinutesLastWeek: number;
  totalLifetimeCourses: number;
}

export interface CompleteContentInput {
  student: string;
  contentId: string;
}

export interface Student {
  id: string;
  name: string;
  email: string;
  achievements: number;
  curriculums: number;
  courses: number;
  calendarEvents: CalendarEvent[];
}

export interface RecommendedCourses {
  quickRefresher?: TrainingGroupDetails;
  challenge?: TrainingGroupDetails;
  quickRefresherParent?: TrainingGroupDetails;
  challengeParent?: TrainingGroupDetails;
}

export interface StudentWeeklyProgress {
  week: string;
  pass: number;
  fail: number;
}

export interface GroupProgress {
  group: TrainingGroupPreview;
  completed: number;
  total: number;
  updatedAt: Date;
}

export interface Preference {
  name: string;
  value: string;
}

export interface UserGroup {
  id: string;
  name: string;
  description?: string;
  users: User[];
}

export enum Role {
  NONE = '',
  ADMIN = 'Admin',
  ANALYST = 'Analyst',
  INSTRUCTOR = 'Instructor',
  BUSINESS = 'Business',
  ORGANIZATIONAL = 'Organizational',
  REPORTING = 'Reporting',
  SELF_TESTER = 'Self-tester',
  FRAMEWORK_ANALYST = 'Framework Analyst',
}

export interface User {
  id: string;
  active: boolean;
  name: string;
  email: string;
  group: string;
  preferences: Preference[];
  status: 'Active' | 'Disabled';
  updatedAt: Date;
  createdAt: Date;
  roles: Role[];
  online: boolean;
  requirePasswordReset: boolean;
}

export interface UserInput {
  id?: string;
  name?: string;
  email?: string;
  password?: string;
  aliases?: string[];
  group?: string;
  roles?: string[];
  status?: string;
  created?: Date;
  requirePasswordReset?: boolean;
}

export interface UserGroupInput {
  id?: string;
  name: string;
  description?: string;
  users?: string[];
}

export interface UserSignUpInput {
  name: string;
  email: string;
  password: string;
  token: string;
}

export interface SystemUser {
  type: string;
  systemUser: string;
  password: string;
  url?: string;
  host?: string;
  database?: string;
  port?: number;
  query?: string;
  protocol?: string;
  integrationType?: string;
  updatedAt?: Date;
}

export interface Setting {
  name: string;
  value: string;
  updatedAt: Date;
}

export interface SettingInput {
  name: string;
  value: string;
}

export interface WeightsAndThresholds {
  likelihood: {
    technologyWeight: number;
    noResponseWeight: number;
    threatScoreWeight: number;
  };
  riskPosture: {
    attackScoreWeight: number;
    passFailScoreThreshold: number;
  };
  ipWeight: number;
  piiWeight: number;
  severityThreshold: number;
  testResultScore: {
    eventScoreWeight: number;
    noiseScoreWeight: number;
    responseScoreWeight: number;
  };
  threatPreparedness: {
    unknownTechniques: number;
    preventionProbability: number;
    detectionProbability: number;
    alertProbability: number;
    responseProbability: number;
  };
}

export interface SupportLink {
  title: string;
  description?: string;
  url: string;
}

export interface Support {
  phone: string;
  email: string;
  links: SupportLink[];
}

export interface Token {
  id: string;
  code: string;
  expiration: string;
  revoked: boolean;
  createdAt: Date;
}

export interface TokenCreateInput {
  expirationDays: number;
}

export interface TestingProduct {
  id: string;
  name: string;
  version: string;
}

export interface TestingLibrary {
  id: string;
  name: string;
}

export interface TestingSetting {
  name: string;
  value: string;
}

export interface TestingSettingCreate {
  name: string;
  value: string;
}

export interface Endpoint {
  id: string;
  hostname: string;
  os: string;
  products: TestingProduct[];
  createdAt: Date;
}

export interface Product {
  id: string;
  vendorId: string;
  name: string;
  type: string;
  description?: string;
  version?: string;
}

export interface CallToAction {
  label?: string;
  url?: string;
}

export interface Notification {
  id: string;
  title: string;
  text: string;
  read: boolean;
  callToAction?: CallToAction;
  createdAt: Date;
}

export interface RoiInput {
  assessmentId: string;
  versionId: string;
}

export interface RoiCostBenefit {
  labels: string[];
  cost: number[];
  value: number[];
}

export interface RoiPerformanceToROI {
  labels: string[];
  cost: number[];
  value: number[];
  roi: number[];
  prevention: number[];
  detection: number[];
}

export interface RoiRiskReductionSeries {
  name: string;
  data: number[];
  limit?: number[];
}

export interface RoiRiskReduction {
  labels: string[];
  series: RoiRiskReductionSeries[];
}

export interface Ratio {
  value: number;
  total: number;
}

export interface RoiActionRatio {
  name: string;
  data: Ratio[];
}

export interface RoiInvestmentAction {
  labels: string[];
  ratios: RoiActionRatio[];
}

export interface RoiResult {
  securityPortfolioValue: number;
  roiRatio: number[];
  avgTechnologyValue: number[];
  preventionRate: number[];
  detectionRate: number[];
  actionedTests: number[];
  missedTests: number[];
  technologyCostBenefit: RoiCostBenefit;
  technologyPerformanceToRoi: RoiPerformanceToROI;
  technologyRiskReduction: RoiRiskReduction;
  investmentActions: RoiInvestmentAction;
  updatedAt: Date;
}

export interface ThreatPreparednessInput {
  assessmentId: string;
  versionId: string;
}

export interface ThreatPreparednessStage {
  name: string;
  groupId: string;
  knownTechniques: number;
  testedTechniques: number;
  untestedTechniques: number;
  unknownTechniques: number;
  probabilityOfPrevention: number;
  probabilityOfDetection: number;
  probabilityOfAlerting: number;
  likelihoodOfKnownTechniques: number;
  likelihoodOfUnknownTechniques: number;
  tacticalLikelihoodOfKnownTechniques: number;
  tacticalLikelihoodOfUnknownTechniques: number;
  tacticalLikelihood: number;
  likelihood: number;
}

export interface ThreatPreparednessGroup {
  id: string;
  name: string;
  stages: ThreatPreparednessStage[];
  updatedAt: Date;
}

export interface ApplyContentInput {
  contentId: string;
}

export interface ContentInput {
  files?: File[];
}

export interface ContentPack {
  id: string;
  fileName: string;
  description: string;
  uploadedAt: Date;
  appliedAt?: Date;
}

export enum AggregationMethod {
  AVERAGE = 'AVERAGE',
  LOWEST = 'LOWEST',
}

export enum ScoreType {
  ASSESSOR = 'ASSESSOR',
  CAPMIT = 'CAPMIT',
  HEAT = 'HEAT',
  NONE = 'NONE',
  RAW = 'RAW',
  RELATIVE = 'RELATIVE',
  SECMIT = 'SECMIT',
}

export interface FrameworkScoringInput {
  framework: string;
  version?: string;
  threatGroupId?: string;
  aggregationMethod: string;
  zones?: number[];
  assessmentId: string;
  dateRange: {
    from: Date;
    to: Date;
  };
}

export interface TacticScore {
  score: number | null;
  isValid: boolean | null;
}

export interface MapTestIdentifier {
  identifier: string;
  test: string;
}

export interface ScoredFrameworkItem {
  identifier: string;
  name: string;
  testScore: TacticScore;
  testTrend: Array<number | null>;
  postureScore: TacticScore;
  postureTrend: Array<number | null>;
  threatScore: TacticScore;
  assessorScore: AssessorScore | null;
  assessorValue: number | null;
  variance: number;
  sub: string[];
  testIds: MapTestIdentifier[];
  testsRan: number;
}

export interface ScoredFrameworkGroup {
  name: string;
  items: ScoredFrameworkItem[];
}

export interface SecurityZone {
  id: number;
  name: string;
}

export interface ScoredFramework {
  id: string;
  name: string;
  title: string;
  version: string;
  type: string;
  updatedAt: Date;
  groups: ScoredFrameworkGroup[];
  threatTrends: string[][];
  toDate?: string;
  observedZones: SecurityZone[];
}

export interface Exam {
  id: string;
  name: string;
  description: string;
  vendor: string;
  testIds: string[];
  tests: Test[];
  trainings: LiveFireTraining[];
  __typename: 'Exam';
}

export interface CreateExamInput {
  name: string;
  description: string;
  vendor: string;
  testIds: string[];
}

export interface RunExamInput {
  examId: string;
  stages: RunJobStageInput[];
  source: string;
  analysts: string[];
  timeLimit: number;
  isExcluded?: boolean;
  isSelfTest?: boolean;
  start?: Date;
  end?: Date;
}

export interface SOCReportingData {
  date: Date;
  resolution: string;
  resolutionTime: number;
  prevented: boolean;
  detected: boolean;
  alerted: boolean;
  test: {
    name: string;
    vendor: string;
    vendorId: string;
  };
  job: {
    name: string;
    evaluationId: string;
  };
}

export interface SOCReportingData {
  date: Date;
  resolution: string;
  resolutionTime: number;
  prevented: boolean;
  detected: boolean;
  alerted: boolean;
  test: {
    name: string;
    vendor: string;
    vendorId: string;
  };
  job: {
    name: string;
    evaluationId: string;
  };
}

export interface ExamEvent {
  id: string;
  owner: string;
  attendee: string;
  start: Date;
  end?: Date;
  endTime?: string;
  exam: Exam;
  status: string;
}

export interface ScorePerformanceValues {
  responseTime: number;
  responseScore: number;
  precisionScore: number;
  score: number;
  count: number;
  trainings: number;
}

export interface ScorePerformance {
  label: string;
  performance: ScorePerformanceValues;
}

export interface ResultPerformanceValues {
  prevented: number;
  detected: number;
  alerted: number;
  results: number;
}

export interface ResultPerformance {
  label: string;
  performance: ResultPerformanceValues;
}

export interface OrganizationOverview {
  techPerformanceOverTime: ResultPerformance[];
  techPerformanceByTactic: ResultPerformance[];
  techPreventionRates: ResultPerformance[];
  techPerformanceByAdversary: ResultPerformance[];
  personnelPerformanceOverTime: ScorePerformance[];
  personnelPerformanceByTactic: ScorePerformance[];
  personnelPerformanceByAdversary: ScorePerformance[];
}

export interface AuditRecord {
  createdAt: Date;
  fileName: string;
  user: {
    name: string;
  };
}

export interface FrameworkSource {
  framework: string;
  groups: FrameworkGroupSource[];
  lastModified: string;
  revision?: number;
  title: string;
  type: string;
  version: string;
  isSystem?: boolean;
}

export interface FrameworkGroupSource {
  active: boolean;
  items: FrameworkItemSource[];
  order: number;
  title: string;
  type: string;
}

export interface FrameworkItemSource {
  description?: string;
  id: string;
  sub?: FrameworkItemSource[];
  title: string;
}

export interface License {
  id: string;
  status: string;
  name: string;
  expires: Date;
  allocation: number;
  type: string;
}

export interface RenewLicenseInput {
  id: string;
  file: File;
}

export interface AddLicenseInput {
  files: File[];
}

type WithScoredItems<T> = T extends { items: FrameworkItem[] }
  ? Omit<T, 'items'> & { items: FrameworkItemWithScores[] }
  : T;

export type FrameworkWithScores = Scored<WithScoredItems<Framework>, Record<string, number>>;

export type FrameworkItemWithScores = Scored<FrameworkItem, ScoreMeta[]>;

export interface Scorable {
  scores: ScoreMeta[];
}

export interface ScoreMeta {
  label: string;
  maturityLevel: string;
  score: number;
  name: string;
  passed: boolean;
  threshold?: number;
  inferredBy?: string;
  metadata?: any;
  subsAchieved?: number;
  subsCount?: number;
  questionsCount?: number;
  answersCount?: number;
}

export type Assessment<T extends Framework = never> = {
  id: string;
  name: string;
  createdAt: Date;
  startDate: Date;
  endDate: Date;
  isArchived?: boolean;
  description: string;
  license: {
    id: string;
    name: string;
    expires: Date;
    issued: Date;
    type: string;
  };
  framework: FrameworkSet<T>;
  frameworks: T[];
};

export interface AssessmentListInput {
  search?: {
    name: string;
  };
  pagination?: PaginationInput;
}

export interface Question {
  id: string;
  type: string;
  question: string;
}

export interface Answer {
  question: Question;
  value: string;
  passed: boolean;
}

export interface AnswerInput {
  questionId: string;
  value: string;
}

export interface AssessorNote {
  id: string;
  createdAt: Date;
  note: string;
}

export interface ObjectiveResponse {
  id: string;
  frameworkItem: FrameworkItemWithScores;
  assessorInput: {
    questions: Question[];
    answers: Answer[];
    evidence: FileInfo[];
    notes: AssessorNote[];
  }[];
}

export interface ItemMaturityLevel {
  item: FrameworkItem;
  maturityLevel: string;
  passingValue: number;
}
