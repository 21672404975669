import { makeStyles } from '@mui/styles';
import { useCountUp } from 'use-count-up';

const useStyles = makeStyles(() => ({
  topRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
  },
  value: {
    margin: 0,
    fontFamily: 'Lexend',
    fontSize: 30,
    lineHeight: 1.13,
  },
  valueWithIcon: {
    marginRight: 10,
  },
  label: {
    margin: 0,
    fontFamily: 'Lexend',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.6px',
    color: '#a7adb5',
    textTransform: 'uppercase',
  },
}));

interface LargeNumericMetricProps {
  animate?: boolean;
  value: number | string;
  label: string;
  labelSecondRow?: string;
  unit?: string | null;
  // Icon to render at the end of the row
  icon?: JSX.Element | null;
  formatter?: (v: any) => any;
}

export default function LargeNumericMetric({
  animate = true,
  unit = null,
  labelSecondRow = '',
  icon = null,
  value,
  label,
  formatter = v => v,
}: LargeNumericMetricProps) {
  const classes = useStyles();
  const { value: v } = useCountUp({
    isCounting: true,
    end: value as number,
    duration: 2.4,
  });

  return (
    <div>
      <div className={classes.topRow}>
        <div className={`${classes.value} ${icon && classes.valueWithIcon}`}>
          {animate ? formatter(v) : formatter(value)}
          {unit !== '%' && ' '}
          {unit}
        </div>
        {icon}
      </div>
      <p className={classes.label}>{label}</p>
      {labelSecondRow && <p className={classes.label}>{labelSecondRow}</p>}
    </div>
  );
}
