import clsx from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from '@sightgain/core';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    position: 'relative',
  },
  boxX: {
    display: 'flex',
    '-ms-overflow-style': 'none',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollBehavior: 'smooth',
  },
  boxY: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
  },
  shadowXright: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    backgroundImage: 'linear-gradient(88deg, rgba(41, 75, 106, 0) 4%, rgba(0, 0, 0, 0.51) 95%)',
    width: '3.5%',
  },
  shadowXleft: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    opacity: 0.8,
    backgroundImage: 'linear-gradient(-88deg, rgba(41, 75, 106, 0) 4%, rgba(0, 0, 0, 0.51) 95%)',
    width: '3.5%',
  },
  shadowY: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.8,
    backgroundImage: 'linear-gradient(180deg, rgba(41, 75, 106, 0) 4%, rgba(0, 0, 0, 0.51) 95%)',
    height: '10%',
  },
  rightArrow: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    height: '100%',
    zIndex: 3,
  },
  leftArrow: {
    position: 'absolute',
    left: 0,
    display: 'flex',
    height: '100%',
    zIndex: 3,
  },
}));

function isOverflowActive(event: HTMLDivElement) {
  return event.offsetHeight < event.scrollHeight || event.offsetWidth < event.scrollWidth;
}

export default function ScrollableList({ className = '', direction = 'x', children }: ScrollableListProps) {
  const classes = useStyles();
  const [boxNode, setBoxNode] = useState<HTMLDivElement>();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [overflowActive, setOverflowActive] = useState(false);
  const boxRef = useCallback(
    (node: HTMLDivElement) => {
      if (node) {
        setOverflowActive(isOverflowActive(node));
        setBoxNode(node);
      }
    },
    [children],
  );

  const showArrows = overflowActive && direction === 'x';
  const scrollLeft = () => {
    if (boxNode) boxNode.scrollLeft -= 200;
  };
  const scrollRight = () => {
    if (boxNode) boxNode.scrollLeft += 200;
  };

  const handleScroll = (target: HTMLDivElement): void => {
    setShowLeftArrow(target.scrollLeft > 0);
    setShowRightArrow(target.scrollLeft < target.scrollWidth - target.clientWidth);
  };

  const handleScrollDebounce = useCallback(debounce(handleScroll, 30), []);

  return (
    <div className={classes.wrapper}>
      {showArrows && showLeftArrow && (
        <div className={classes.leftArrow}>
          <IconButton onClick={() => scrollLeft()} size="large">
            <ArrowBackIosIcon fontSize="large" />
          </IconButton>
        </div>
      )}
      {showArrows && showRightArrow && (
        <div className={classes.rightArrow}>
          <IconButton onClick={() => scrollRight()} size="large">
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </div>
      )}
      <Box
        onScroll={e => handleScrollDebounce(e.target)}
        ref={boxRef}
        component="div"
        className={clsx(className, {
          [classes.boxX]: direction === 'x',
          [classes.boxY]: direction === 'y',
        })}
        overflow="auto"
      >
        {children}
      </Box>
      <div
        className={clsx({
          [classes.shadowXleft]: direction === 'x' && showArrows && showLeftArrow,
        })}
      />
      <div
        className={clsx({
          [classes.shadowXright]: direction === 'x' && showArrows && showRightArrow,
          [classes.shadowY]: direction === 'y' && overflowActive,
        })}
      />
    </div>
  );
}

type ScrollableListProps = {
  className?: string;
  direction?: 'x' | 'y';
  children?: ReactNode | ReactNode[];
};
