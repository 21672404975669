import { gql } from '@apollo/client';
import { RiskScenario } from './interfaces';
import ServiceBase from './ServiceBase';

const typeRiskScenario = `
{
  id
  name
  type
  groups {
    id
    name
    items {
      id
      name
      identifier
      technologies
    }
  }
}`;

export class RiskScenarioService extends ServiceBase {
  /**
   * Fetches the risk scenarios from the API
   * @returns {Promise<RiskScenario[]>}
   */
  async list(fields = typeRiskScenario): Promise<RiskScenario[]> {
    const query = gql`query RiskScenarios { riskScenarios ${fields} }`;
    const { riskScenarios } = await this.graphql(query);
    return riskScenarios;
  }
}

const riskScenarioService = new RiskScenarioService();
export default riskScenarioService;
