import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  bar: {
    width: '10px',
    height: '20px',
    background: theme.palette.subduedText.main,
  },
  barContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '150px',
  },
  solid: {
    background: theme.palette.skyBlue.main,
  },
}));

export default function ProgressColumns({ numberOfBars = 10, percentageFilled }: ProgressColumnsProps) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.barContainer}>
        {Array.from({ length: numberOfBars }).map((bar, idx) => {
          return (
            <div
              key={idx}
              className={clsx(classes.bar, {
                [classes.solid]: idx < Math.floor((percentageFilled / 100) * numberOfBars),
              })}
            />
          );
        })}
      </div>
    </>
  );
}

type ProgressColumnsProps = {
  numberOfBars?: number;
  percentageFilled: number;
};
