import { gql } from '@apollo/client';
import { SystemUser } from './interfaces';
import ServiceBase from './ServiceBase';

export class TicketingService extends ServiceBase {
  async settings(configuration: SystemUser, data: unknown) {
    const dataString = JSON.stringify(data);
    const query = gql`
      query TicketingSettings($input: SystemUserDataInput!, $data: String!) {
        ticketingSettings(input: $input, data: $data)
      }
    `;

    const { ticketingSettings } = await this.graphql(query, { input: configuration, data: dataString }, 'network-only');
    return JSON.parse(ticketingSettings);
  }

  async saveSettings(integrationType: string, data: unknown) {
    const dataString = JSON.stringify(data);

    const query = gql`
      mutation ticketingSaveSettings($integrationType: String!, $data: String!) {
        ticketingSaveSettings(integrationType: $integrationType, data: $data)
      }
    `;

    const { ticketingSaveSettings } = await this.graphql(query, { integrationType, data: dataString });
    return ticketingSaveSettings;
  }

  async testConnection(configuration: SystemUser) {
    const query = gql`
      mutation ticketingConnection($input: SystemUserDataInput!) {
        ticketingConnection(input: $input)
      }
    `;

    const { ticketingConnection } = await this.graphql(query, { input: configuration });
    return ticketingConnection;
  }
}

const ticketingService = new TicketingService();
export default ticketingService;
