import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import ChartWrapper from './ChartWrapper';
import { allColors } from './utils';

type CurveType = 'straight' | 'smooth';

interface Series {
  name: string;
  type?: string;
  data: number[];
}

interface ValueAxisSeriesLabels {
  formatter?: (value: number) => string;
}

interface ValueAxisSeries {
  seriesName?: string;
  labels?: ValueAxisSeriesLabels;
  min?: number;
  max?: number;
  decimalsInFloat?: number;
  show?: boolean;
}

interface ValueAxis {
  series?: ValueAxisSeries[];
  ticks?: number;
}

interface LabelAxis {
  formatter?: (value: string) => string;
}

interface Tooltip {
  formatterY?: (value: number, { index }: { index: number }) => string | number;
  formatterX?: (value: string) => string | number;
}

interface Stroke {
  width?: number[];
  curve?: CurveType[];
}

interface DataLabels {
  enabled?: boolean;
  enabledOnSeries?: number[];
}

interface DashboardMixedChartMetricProps {
  title: string;
  labels: string[];
  series: Series[];
  valueAxis?: ValueAxis;
  labelAxis?: LabelAxis;
  tooltip?: Tooltip;
  stroke?: Stroke;
  dataLabels?: DataLabels;
  columnWidth?: string;
  fillOpacity?: number[];
  otherOptions?: Record<string, unknown>;
}

export default function DashboardMixedChartMetric({
  title,
  labels,
  series,
  valueAxis = { series: [], ticks: 4 },
  labelAxis = { formatter: v => v },
  tooltip = {
    formatterY: (v, { index }) => v,
    formatterX: undefined,
  },
  stroke = {
    width: [2, 0, 5],
    curve: ['straight', 'smooth', 'straight'],
  },
  dataLabels = {
    enabled: false,
  },
  columnWidth = '50%',
  fillOpacity = [1, 0.39, 1],
  otherOptions = {},
}: DashboardMixedChartMetricProps) {
  const colors = allColors.slice(0, series.length);

  const valueAxisOptions = valueAxis?.series?.map(value => ({
    ...value,
    labels: {
      ...value.labels,
      style: {
        colors: labels.map(() => '#a7adb5'),
        fontSize: 12,
        fontFamily: 'Lexend',
        fontWeight: 'normal',
      },
    },
    tickAmount: valueAxis.ticks,
  }));

  const labelAxisOptions = {
    labels: {
      formatter: labelAxis.formatter,
      style: {
        colors: labels.map(() => '#a7adb5'),
        fontSize: 12,
        fontFamily: 'Lexend',
        fontWeight: 'normal',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  };

  const options = {
    chart: {
      foreColor: '#ffffff',
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        mounted: (chart: any) => {
          chart.windowResizeHandler();
        },
      },
    },
    stroke,
    fill: {
      opacity: fillOpacity,
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.39,
        opacityTo: 0.39,
        stops: [0, 100, 100, 100],
      },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth,
      },
    },
    labels,
    colors,
    dataLabels,
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '12px',
      fontFamily: 'Lexend',
      fontWeight: 'normal',
      labels: {
        colors: ['#fff'],
      },
      markers: {
        radius: 12,
      },
    },
    xaxis: labelAxisOptions,
    yaxis: valueAxisOptions,
    tooltip: {
      theme: 'dark',
      shared: true,
      intersect: false,
      y: {
        formatter: tooltip.formatterY,
      },
      x: {
        formatter: tooltip.formatterX,
      },
    },
    ...otherOptions,
  };

  return (
    <ChartWrapper title={title}>
      {!series.length ? (
        <CircularProgress disableShrink />
      ) : (
        <Chart options={options} height="100%" series={series} type="line" />
      )}
    </ChartWrapper>
  );
}
