import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import ChartWrapper from './ChartWrapper';
import { allColors } from './utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  chart: {
    paddingRight: 13,
    paddingBottom: 13,
    paddingTop: 13,
  },
}));

interface Series {
  name: string;
  type: string;
  data: number[][];
}

interface TimeSeriesMetricProps {
  title: string;
  series: Series[];
}

export default function TimeSeriesMetric({ title, series }: TimeSeriesMetricProps) {
  const classes = useStyles();
  const colors = allColors.slice(0, series.length);

  const options = {
    chart: {
      type: 'line',
      foreColor: '#999',
      toolbar: {
        show: false,
      },
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true,
      },
      events: {
        mounted: (chart: any) => {
          chart.windowResizeHandler();
        },
      },
    },
    colors,
    stroke: {
      width: [2, 0, 5],
      curve: ['straight', 'smooth', 'straight'],
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: title,
        data: series,
      },
    ],
    markers: {
      size: 0,
      strokeColor: '#fff',
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        offsetX: 0,
        offsetY: -4,
        formatter: function (value: number) {
          return Math.trunc(value);
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        left: 8,
      },
      show: false,
    },
    tooltip: {
      theme: 'dark',
      shared: true,
      intersect: false,
      x: {
        format: 'dd MMM yyyy',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
  };

  return (
    <ChartWrapper title={title} chartClassName={classes.chart}>
      {series.length === 0 ? (
        <CircularProgress disableShrink />
      ) : (
        <Chart options={options} height="100%" series={series} type="line" />
      )}
    </ChartWrapper>
  );
}
