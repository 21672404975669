import { useCallback, useMemo, useState } from 'react';
import { capitalize } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import CsvDownload from '../../../reusables/CsvDownload';
import { formatterPercent, HeatmapChartMetric } from '../../../reusables/metrics';
import { settingsService, threatPrepService } from '../../../services';
import { ThreatPreparednessGroup } from '../../../services/interfaces';
import Title from '../components/Title';

export default function ThreatPreparedness() {
  const [prep, setPrep] = useState<ThreatPreparednessGroup[]>([
    {
      id: 'threat-group-alpha',
      name: 'Alpha',
      updatedAt: new Date(),
      stages: defaultStages.map(stage => ({
        name: stage,
        groupId: 'threat-group-alpha',
        knownTechniques: 0,
        testedTechniques: 0,
        untestedTechniques: 0,
        unknownTechniques: 0,
        probabilityOfPrevention: 0,
        probabilityOfDetection: 0,
        probabilityOfAlerting: 0,
        likelihoodOfKnownTechniques: 0,
        likelihoodOfUnknownTechniques: 0,
        tacticalLikelihoodOfKnownTechniques: 0,
        tacticalLikelihoodOfUnknownTechniques: 0,
        tacticalLikelihood: 0,
        likelihood: 0,
      })),
    },
  ]);

  const input = useMemo(() => ({ assessmentId: '', versionId: '' }), []);

  const [thresholds, setThresholds] = useState<number[]>([]);

  useAbort(
    () => threatPrepService.preparedness(input),
    result => setPrep(result),
    [input],
  );
  useAbort(
    () => settingsService.find('threatPreparednessThresholds'),
    setting => {
      if (!setting) {
        return;
      }

      const values = (JSON.parse(setting.value) as number[]).map(v => +v / 100);

      setThresholds(values);
    },
    [],
  );

  const handleRefreshClick = useCallback(async () => {
    appStore.beginLoading();
    try {
      const result = await threatPrepService.refreshPreparedness(input);
      setPrep(result);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }, [input]);

  const labels = prep[0].stages.map(p => capitalize(p.name));
  const series = prep.map(group => ({ name: group.name, data: group.stages.map(s => s.likelihood) }));

  const datas = () => {
    return series.map(s => ({
      name: s.name,
      ...labels.reduce((acc, l, idx) => {
        acc[l] = `${s.data[idx] * 100}` || '';
        return acc;
      }, {} as Record<string, string>),
    }));
  };

  return (
    <div data-testid="view-threat-preparedness">
      <Title title="Threat Preparedness" scoredDate={prep[0]?.updatedAt} refresh={handleRefreshClick} />
      <HeatmapChartMetric
        title={<CsvDownload filename="ThreatPreparedness.csv" datas={datas} />}
        labels={labels}
        series={series}
        from={0}
        to={1}
        formatter={formatterPercent}
        variant="thresholds"
        thresholds={thresholds}
      />
    </div>
  );
}

const defaultStages = [
  'initial access',
  'execution',
  'persistence',
  'privilege escalation',
  'defense evasion',
  'credential access',
  'discovery',
  'lateral movement',
  'collection',
  'command and control',
  'exfiltration',
  'impact',
];
