import { gql } from '@apollo/client';
import { SystemUser } from './interfaces';
import ServiceBase from './ServiceBase';

export class VendorService extends ServiceBase {
  async testConnection(configuration: SystemUser): Promise<string> {
    const query = gql`
      mutation VendorConnection($input: SystemUserDataInput!) {
        vendorConnection(input: $input)
      }
    `;

    const { vendorConnection } = await this.graphql(query, { input: configuration });
    return vendorConnection;
  }

  async reconnect(integrationType: string): Promise<string> {
    const query = gql`
      mutation VendorReconnect($integrationType: String!) {
        vendorReconnect(integrationType: $integrationType)
      }
    `;

    const { vendorReconnect } = await this.graphql(query, { integrationType });
    return vendorReconnect;
  }

  async refresh(): Promise<string> {
    const query = gql`
      mutation {
        vendorRefresh
      }
    `;
    const { vendorRefresh } = await this.graphql(query);
    return vendorRefresh;
  }
}

const vendorService = new VendorService();
export default vendorService;
