import { useEffect, useState } from 'react';

let context: __WebpackModuleApi.RequireContext | object = {};

try {
  context = require.context('../../../../images/products/', false, /\.(png|jpe?g|svg)$/);
} catch (err) {
  context = {};
  // Silent catch to prevent failing JEST tests (webpack require context is not present in unit tests)
}

const obj: Record<string, string> = {};
if ('keys' in context && typeof context.keys === 'function') {
  context.keys().forEach((key: string) => {
    const countryCode = (key.split('./').pop() as string).substring(0, key.length - 6); // remove the file extension
    obj[countryCode] = context(key);
  });
}

const fileNameKeys = Object.keys(obj);

function levenshteinDistance(a: string, b: string) {
  if (!a || !b) return (a || b).length;
  const matches = [];
  for (let i = 0; i <= b.length; i += 1) {
    matches[i] = [i];
    if (i !== 0) {
      for (let j = 0; j <= a.length; j += 1) {
        matches[0][j] = j;
        if (j !== 0) {
          matches[i][j] =
            b.charAt(i - 1) === a.charAt(j - 1)
              ? matches[i - 1][j - 1]
              : Math.min(matches[i - 1][j - 1] + 1, matches[i][j - 1] + 1, matches[i - 1][j] + 1);
        }
      }
    }
  }
  return matches[b.length][a.length];
}

export default function ProductIcon({ productName, className = '' }: ProductIconProps) {
  const [image, setImage] = useState('');

  useEffect(() => {
    const distances = fileNameKeys.map(fileNameKey => ({
      item: fileNameKey,
      dist: levenshteinDistance(fileNameKey, productName),
    }));
    distances.sort((left, right) => left.dist - right.dist);
    if (distances && distances[0]) {
      setImage(obj[distances[0].item]);
    } else {
      setImage('');
    }
  }, [productName]);

  return image ? <img src={image} alt="productImage" className={className} /> : null;
}

type ProductIconProps = {
  productName: string;
  className?: string;
};
