import { useContext, useEffect } from 'react';
import { unique } from '@sightgain/core';
import { EvaluationNodeConfig } from '../../../interfaces';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import Node from './Node';

export default function Nodes() {
  const {
    actorOptions,
    actorSettings,
    testOptions,
    testOptionInputs,
    selected,
    stages,
    label,
    handleDisableNextBtn,
    handleUpdateTestOption,
    handleUpdateActorSettings,
  } = useContext(EvaluationWindowContext);

  const byStage = ['msv', 'srp'].includes(selected?.vendor ?? '');

  const defaultNodeConfig: EvaluationNodeConfig = {
    type: '',
    target: '',
    attacker: '',
  };

  useEffect(() => {
    if (!actorOptions) {
      return;
    }

    // start off with a simple count
    let disabled = actorOptions.length !== actorSettings.length;

    if (!disabled) {
      // ensure that an attacker and a target exists as required
      disabled = !!actorOptions.find((option, indx) => {
        const conf = actorSettings[indx];
        if (option.sourceActorIds.length && !conf?.attacker) {
          return true;
        }

        if (option.destinationActorIds.length && !conf?.target) {
          return true;
        }

        return false;
      });
    }

    // only need to check for required arguments
    // when the button would be enabled
    if (!disabled && testOptions.length) {
      const required = testOptions
        .flat()
        .filter(opt => opt.isRequired)
        .map(opt => opt.key);
      const inputs = testOptionInputs.flat().filter(input => required.includes(input.key));

      disabled = required.length !== inputs.length;
    }

    handleDisableNextBtn(disabled);
  }, [actorOptions, actorSettings, testOptionInputs, testOptions, handleDisableNextBtn]);

  if (!(actorOptions && selected)) {
    return <></>;
  }

  const nodesByEval = () => {
    const settings = actorSettings.length > 0 ? actorSettings[0] : {};
    const nodeConfig = { ...defaultNodeConfig, type: 'host_cli', ...settings };

    return (
      <Node
        key={`evaluation-node-${selected.id}`}
        description={selected.name}
        nodeConfig={nodeConfig}
        actorOptions={actorOptions[0]}
        testOptions={testOptions.length ? testOptions[0] : []}
        testInputs={testOptionInputs[0]}
        updateActor={setting => handleUpdateActorSettings([setting])}
        updateOptions={input => handleUpdateTestOption(0, input)}
      />
    );
  };

  /**
   * @returns an array of EvaluationNode
   */
  const nodesByStage = () =>
    stages
      // only show nodes that have actor options
      .slice(0, actorOptions.length)
      .map((stage, i) => {
        const settings = actorSettings.length > i ? actorSettings[i] : {};
        const nodeConfig = { ...defaultNodeConfig, type: stage.tests[0].type, ...settings };

        const onUpdateActor = (config: EvaluationNodeConfig) => {
          if (actorSettings.length < i) {
            return handleUpdateActorSettings([...actorSettings, config]);
          }

          const updated = [...actorSettings];
          updated.splice(i, 1, config);
          return handleUpdateActorSettings(updated);
        };

        const tags = unique(
          stage.tests.flatMap(test =>
            test.tags.filter(tag => tag.match(/(^|\+)(Source|Destination):/)).flatMap(tag => tag.split('+')),
          ),
        ).sort((a, b) => {
          if (a.startsWith('Source') && b.startsWith('Destination')) {
            return -1;
          }

          if (a.startsWith('Destination') && b.startsWith('Source')) {
            return 1;
          }

          return a.localeCompare(b);
        });

        return (
          <Node
            key={`evaluation-node-${stage.tests[0].vendorId}`}
            tags={tags}
            description={stage.name}
            nodeConfig={nodeConfig}
            actorOptions={actorOptions[i]}
            testOptions={testOptions[i]}
            testInputs={testOptionInputs[i]}
            updateActor={onUpdateActor}
            updateOptions={input => handleUpdateTestOption(i, input)}
          />
        );
      });

  return <>{byStage || label === 'exam' ? nodesByStage() : nodesByEval()}</>;
}

Nodes.label = 'Select {label} parameters';
